import type { BlockData } from "@sunblocks/game";

type Position = [y: number, x: number];

type Shape = Omit<BlockData, "initialPosition">;

export const source: Shape = {
  source: true,
  shape: [[0, 0]],
};

export const sink: Shape = {
  sink: true,
  shape: [[0, 0]],
};

export const rectangle = (height: number, width: number) => ({
  shape: Array.from({ length: height }).flatMap((foo, y) =>
    Array.from({ length: width }).map((foo2, x) => [y, x] satisfies Position)
  ),
});

export const lTopLeft: Shape = {
  shape: [
    [0, 0],
    [0, 1],
    [0, 2],
    [1, 0],
  ],
};

export const lTopRight: Shape = {
  shape: [
    [0, 0],
    [0, 1],
    [1, 1],
    [2, 1],
  ],
};

export const lBottomLeft: Shape = {
  shape: [
    [0, 0],
    [1, 0],
    [2, 0],
    [2, 1],
  ],
};

export const lBottomRight: Shape = {
  shape: [
    [0, 2],
    [1, 0],
    [1, 1],
    [1, 2],
  ],
};

export const reverseLTopLeft: Shape = {
  shape: [
    [0, 0],
    [0, 1],
    [1, 0],
    [2, 0],
  ],
};

export const reverseLTopRight: Shape = {
  shape: [
    [0, 0],
    [0, 1],
    [0, 2],
    [1, 2],
  ],
};

export const reverseLBottomLeft: Shape = {
  shape: [
    [0, 0],
    [1, 0],
    [1, 1],
    [1, 2],
  ],
};

export const reverseLBottomRight: Shape = {
  shape: [
    [0, 1],
    [1, 1],
    [2, 0],
    [2, 1],
  ],
};

export const shortLTopLeft: Shape = {
  shape: [
    [0, 0],
    [0, 1],
    [1, 0],
  ],
};

export const shortLTopRight: Shape = {
  shape: [
    [0, 0],
    [0, 1],
    [1, 1],
  ],
};

export const shortLBottomLeft: Shape = {
  shape: [
    [0, 0],
    [1, 0],
    [1, 1],
  ],
};

export const shortLBottomRight: Shape = {
  shape: [
    [0, 1],
    [1, 0],
    [1, 1],
  ],
};

export const tLeft: Shape = {
  shape: [
    [0, 1],
    [1, 0],
    [1, 1],
    [2, 1],
  ],
};

export const tRight: Shape = {
  shape: [
    [0, 0],
    [1, 0],
    [1, 1],
    [2, 0],
  ],
};

export const tUp: Shape = {
  shape: [
    [0, 1],
    [1, 0],
    [1, 1],
    [1, 2],
  ],
};

export const tDown: Shape = {
  shape: [
    [0, 0],
    [0, 1],
    [0, 2],
    [1, 1],
  ],
};

export const longTLeft: Shape = {
  shape: [
    [0, 2],
    [1, 0],
    [1, 1],
    [1, 2],
    [2, 2],
  ],
};

export const longTRight: Shape = {
  shape: [
    [0, 0],
    [1, 0],
    [1, 1],
    [1, 2],
    [2, 0],
  ],
};

export const longTUp: Shape = {
  shape: [
    [0, 1],
    [1, 1],
    [2, 0],
    [2, 1],
    [2, 2],
  ],
};

export const longTDown: Shape = {
  shape: [
    [0, 0],
    [0, 1],
    [0, 2],
    [1, 1],
    [2, 1],
  ],
};

export const longLBottomLeft: Shape = {
  shape: [
    [0, 0],
    [1, 0],
    [2, 0],
    [2, 1],
    [2, 2],
  ],
};

export const longLBottomRight: Shape = {
  shape: [
    [0, 2],
    [1, 2],
    [2, 0],
    [2, 1],
    [2, 2],
  ],
};

export const longLTopLeft: Shape = {
  shape: [
    [0, 0],
    [0, 1],
    [0, 2],
    [1, 0],
    [2, 0],
  ],
};

export const longLTopRight: Shape = {
  shape: [
    [0, 0],
    [0, 1],
    [0, 2],
    [1, 2],
    [2, 2],
  ],
};

export const s: Shape = {
  shape: [
    [0, 1],
    [0, 2],
    [1, 0],
    [1, 1],
  ],
};

export const s90: Shape = {
  shape: [
    [0, 0],
    [1, 0],
    [1, 1],
    [2, 1],
  ],
};

export const plus: Shape = {
  shape: [
    [0, 1],
    [1, 0],
    [1, 1],
    [1, 2],
    [2, 1],
  ],
};

export const z: Shape = {
  shape: [
    [0, 0],
    [0, 1],
    [1, 1],
    [1, 2],
  ],
};

export const z90: Shape = {
  shape: [
    [0, 1],
    [1, 0],
    [1, 1],
    [2, 0],
  ],
};
