import { flatMap, keyBy, mapValues } from "lodash/fp";

import { unflow } from "@sunblocks/utils";

import { area1 } from "./area-1";
import { area2 } from "./area-2";
import { area3 } from "./area-3";
import { area4 } from "./area-4";
import { area5 } from "./area-5";
import { area6 } from "./area-6";
import { area7 } from "./area-7";
import { area8 } from "./area-8";
import { area9 } from "./area-9";
import { area10 } from "./area-10";

export * from "./shapes";

export { area1 } from "./area-1";
export { area2 } from "./area-2";
export { area3 } from "./area-3";
export { area4 } from "./area-4";
export { area5 } from "./area-5";
export { area6 } from "./area-6";
export { area7 } from "./area-7";
export { area8 } from "./area-8";
export { area9 } from "./area-9";
export { area10 } from "./area-10";

export const areas = [
  area1,
  area2,
  area3,
  area4,
  area5,
  area6,
  area7,
  area8,
  area9,
  area10,
];

export const levels = areas.flatMap(({ levels }) => levels);

export const levelById = unflow(
  areas,
  flatMap(({ levels }) => levels),
  keyBy(({ _id }) => `${_id}`)
);

export const areaByLevelId = unflow(
  areas,
  flatMap((area) => area.levels.map((level) => ({ area, level }))),
  keyBy(({ level: { _id } }) => `${_id}`),
  mapValues(({ area }) => area)
);
