import { ObjectId } from "mongodb/lib/bson";

import type { AreaData, BlockData } from "@sunblocks/game";
import { zArea } from "@sunblocks/game";

import { area7 } from "./area-7";
import {
  lBottomLeft,
  lBottomRight,
  lTopLeft,
  lTopRight,
  longLBottomRight,
  longLTopLeft,
  longLTopRight,
  longTLeft,
  longTRight,
  plus,
  rectangle,
  reverseLBottomLeft,
  reverseLBottomRight,
  reverseLTopLeft,
  reverseLTopRight,
  shortLBottomLeft,
  shortLBottomRight,
  shortLTopLeft,
  shortLTopRight,
  sink,
  source,
  tDown,
  tLeft,
  tRight,
  tUp,
} from "./shapes";

const level7Ids = area7.levels.map(({ _id }) => _id);

export const area8 = zArea.parse({
  _id: new ObjectId("66bbca503a67736625ed2bed"),
  models: {
    available: { fill: "fire" },
    won: { fill: "water" },
    wonBest: { fill: "water", model: { sink: "water" } },
  },
  background: "fire",
  levels: [
    {
      _id: new ObjectId("66bbca793a67736625ed2c45"),
      tutorial: true,
      condition: {
        type: "levels",
        count: 12,
        levelIds: level7Ids,
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 2] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [2, 1] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 2], ...rectangle(1, 1), source: "water" },
        { initialPosition: [1, 0], ...rectangle(1, 1) },
        { initialPosition: [1, 1], ...rectangle(1, 1) },
        { initialPosition: [2, 1], ...rectangle(1, 1), sink: "water" },
      ],
    },
    {
      _id: new ObjectId("66bbca793a67736625ed2c46"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbca793a67736625ed2c45")],
      },
      cells: [
        { position: [0, 1] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [4, 1] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source },
        { initialPosition: [1, 0], ...rectangle(1, 1) },
        { initialPosition: [1, 1], ...rectangle(1, 1) },
        { initialPosition: [2, 0], ...rectangle(1, 1), source: "water" },
        { initialPosition: [2, 1], ...rectangle(1, 1) },
        { initialPosition: [2, 2], ...sink },
        { initialPosition: [3, 1], ...rectangle(1, 1) },
        { initialPosition: [4, 1], ...rectangle(1, 1), sink: "water" },
      ],
    },
    {
      _id: new ObjectId("66bbca793a67736625ed2c47"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbca793a67736625ed2c45")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source },
        { initialPosition: [1, 0], ...rectangle(1, 1) },
        { initialPosition: [3, 0], ...rectangle(2, 2) },
        { initialPosition: [5, 0], ...tRight },
        { initialPosition: [5, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 2], ...rectangle(1, 1) },
        { initialPosition: [7, 1], ...rectangle(1, 1) },
        { initialPosition: [7, 2], ...sink },
        ...(
          [
            { initialPosition: [0, 2], ...rectangle(3, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({
          ...block,
          mobile: true,
          source: "water" as const,
        })),
      ],
    },
    {
      _id: new ObjectId("66bbca793a67736625ed2c48"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbca793a67736625ed2c45")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 2], ...rectangle(1, 1), source: "water" },
        { initialPosition: [3, 0], ...reverseLTopRight },
        { initialPosition: [4, 0], ...shortLTopRight },
        { initialPosition: [6, 0], ...longLTopRight },
        { initialPosition: [7, 0], ...shortLTopRight },
        { initialPosition: [7, 3], ...shortLTopRight },
        { initialPosition: [6, 4], ...lTopRight },
        { initialPosition: [6, 6], ...rectangle(1, 1), sink: "water" },
        { initialPosition: [8, 6], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbca793a67736625ed2c49"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbca793a67736625ed2c45")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...shortLBottomRight },
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 2], ...shortLBottomLeft },
        { initialPosition: [0, 5], ...rectangle(2, 1) },
        { initialPosition: [2, 2], ...shortLTopRight },
        { initialPosition: [3, 0], ...rectangle(3, 3) },
        { initialPosition: [3, 4], ...rectangle(3, 3) },
        { initialPosition: [4, 3], ...rectangle(1, 1), source: "water" },
        { initialPosition: [5, 3], ...shortLBottomLeft },
        { initialPosition: [7, 1], ...rectangle(2, 1) },
        { initialPosition: [7, 3], ...shortLTopRight },
        { initialPosition: [7, 5], ...shortLTopLeft },
        { initialPosition: [8, 3], ...rectangle(1, 1), sink: "water" },
        { initialPosition: [8, 6], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbca793a67736625ed2c4a"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbca793a67736625ed2c45")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [0, 2], ...rectangle(1, 1) },
        { initialPosition: [0, 3], ...rectangle(1, 1) },
        { initialPosition: [1, 0], ...rectangle(1, 1) },
        { initialPosition: [1, 1], ...rectangle(1, 1) },
        { initialPosition: [1, 3], ...rectangle(1, 1) },
        { initialPosition: [2, 0], ...rectangle(1, 1) },
        { initialPosition: [2, 1], ...sink, sink: "water", mobile: true },
        { initialPosition: [2, 2], ...rectangle(1, 1) },
        { initialPosition: [2, 3], ...source, source: "water", mobile: true },
        { initialPosition: [3, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 1], ...rectangle(1, 1) },
        { initialPosition: [3, 2], ...rectangle(1, 1) },
        { initialPosition: [3, 3], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbca793a67736625ed2c4b"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbca793a67736625ed2c45")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...rectangle(1, 1) },
        { initialPosition: [0, 5], ...rectangle(1, 1) },
        { initialPosition: [1, 0], ...rectangle(1, 1) },
        { initialPosition: [1, 1], ...reverseLTopLeft },
        { initialPosition: [1, 4], ...lTopRight },
        { initialPosition: [1, 6], ...rectangle(1, 1) },
        { initialPosition: [2, 2], ...longLTopLeft },
        { initialPosition: [3, 2], ...longLBottomRight },
        { initialPosition: [3, 3], ...source, mobile: true },
        { initialPosition: [4, 1], ...lBottomLeft },
        { initialPosition: [4, 3], ...rectangle(1, 1), sink: "water" },
        { initialPosition: [4, 4], ...reverseLBottomRight },
        { initialPosition: [6, 0], ...rectangle(1, 1) },
        { initialPosition: [6, 3], ...sink },
        { initialPosition: [6, 6], ...rectangle(1, 1) },
        { initialPosition: [7, 1], ...rectangle(1, 1) },
        { initialPosition: [7, 5], ...rectangle(1, 1) },
        { initialPosition: [1, 3], ...source, source: "water", mobile: true },
      ],
    },
    {
      _id: new ObjectId("66bbca793a67736625ed2c4c"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbca793a67736625ed2c45")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [0, 7] },
        { position: [0, 8] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [1, 7] },
        { position: [1, 8] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [2, 7] },
        { position: [2, 8] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [3, 7] },
        { position: [3, 8] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [4, 7] },
        { position: [4, 8] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [5, 7] },
        { position: [5, 8] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [6, 7] },
        { position: [6, 8] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [7, 7] },
        { position: [7, 8] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [8, 7] },
        { position: [8, 8] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...plus },
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 3], ...plus },
        { initialPosition: [0, 6], ...plus },
        { initialPosition: [0, 8], ...rectangle(1, 1), source: "water" },
        { initialPosition: [2, 2], ...rectangle(2, 2) },
        { initialPosition: [2, 5], ...rectangle(2, 2) },
        { initialPosition: [3, 0], ...plus },
        { initialPosition: [3, 6], ...plus },
        { initialPosition: [6, 0], ...plus },
        { initialPosition: [6, 3], ...plus },
        { initialPosition: [6, 6], ...plus },
        { initialPosition: [8, 0], ...sink },
        { initialPosition: [8, 8], ...rectangle(1, 1), sink: "water" },
      ],
    },
    {
      _id: new ObjectId("66bbca793a67736625ed2c4d"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbca793a67736625ed2c45")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 0], ...tLeft },
        { initialPosition: [0, 2], ...tRight },
        { initialPosition: [0, 3], ...rectangle(1, 1), source: "water" },
        { initialPosition: [3, 1], ...rectangle(2, 1) },
        { initialPosition: [3, 2], ...rectangle(1, 1) },
        { initialPosition: [4, 2], ...rectangle(2, 1) },
        { initialPosition: [5, 1], ...rectangle(1, 1) },
        { initialPosition: [6, 0], ...tLeft },
        { initialPosition: [6, 2], ...tRight },
        { initialPosition: [8, 0], ...sink },
        { initialPosition: [8, 3], ...rectangle(1, 1), sink: "water" },
      ],
    },
    {
      _id: new ObjectId("66bbca793a67736625ed2c4e"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbca793a67736625ed2c45")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source, mobile: true },
        { initialPosition: [0, 1], ...longTRight },
        { initialPosition: [0, 3], ...rectangle(1, 2) },
        { initialPosition: [1, 0], ...source, source: "water", mobile: true },
        { initialPosition: [1, 5], ...rectangle(2, 1) },
        { initialPosition: [2, 1], ...plus },
        { initialPosition: [2, 3], ...sink, mobile: true },
        { initialPosition: [2, 4], ...rectangle(5, 1) },
        { initialPosition: [3, 0], ...source, source: "water", mobile: true },
        { initialPosition: [3, 5], ...rectangle(2, 1) },
        { initialPosition: [4, 0], ...source, mobile: true },
        { initialPosition: [4, 1], ...longTRight },
        { initialPosition: [4, 3], ...sink, sink: "water", mobile: true },
        { initialPosition: [5, 5], ...sink, sink: "water", mobile: true },
        { initialPosition: [6, 5], ...sink, mobile: true },
      ],
    },
    {
      _id: new ObjectId("66bbca793a67736625ed2c4f"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbca793a67736625ed2c45")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...shortLTopLeft },
        { initialPosition: [0, 2], ...source, mobile: true },
        { initialPosition: [0, 2], ...tUp },
        { initialPosition: [0, 4], ...source, source: "water", mobile: true },
        { initialPosition: [0, 5], ...shortLTopRight },
        { initialPosition: [2, 0], ...tLeft },
        { initialPosition: [2, 2], ...lBottomLeft },
        { initialPosition: [2, 3], ...lTopRight },
        { initialPosition: [2, 5], ...tRight },
        { initialPosition: [5, 0], ...shortLBottomLeft },
        { initialPosition: [5, 2], ...tDown },
        { initialPosition: [5, 5], ...shortLBottomRight },
        { initialPosition: [6, 2], ...sink, sink: "water", mobile: true },
        { initialPosition: [6, 4], ...sink, mobile: true },
      ],
    },
    {
      _id: new ObjectId("66bbca793a67736625ed2c50"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbca793a67736625ed2c45")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [3, 0] },
        { position: [3, 1], active: true },
        { position: [3, 2] },
        { position: [3, 3], active: true },
        { position: [3, 4] },
        { position: [4, 0] },
        { position: [4, 1], active: true },
        { position: [4, 2] },
        { position: [4, 3], active: true },
        { position: [4, 4] },
        { position: [5, 1], active: true },
        { position: [5, 2] },
        { position: [5, 3], active: true },
        { position: [6, 1], active: true },
        { position: [6, 2] },
        { position: [6, 3], active: true },
        { position: [7, 0] },
        { position: [7, 1], active: true },
        { position: [7, 2] },
        { position: [7, 3], active: true },
        { position: [7, 4] },
        { position: [8, 0] },
        { position: [8, 1], active: true },
        { position: [8, 2] },
        { position: [8, 3], active: true },
        { position: [8, 4] },
        { position: [9, 0] },
        { position: [9, 1] },
        { position: [9, 2] },
        { position: [9, 3] },
        { position: [9, 4] },
        { position: [10, 0] },
        { position: [10, 1] },
        { position: [10, 2] },
        { position: [10, 3] },
        { position: [10, 4] },
        { position: [11, 0] },
        { position: [11, 1] },
        { position: [11, 2] },
        { position: [11, 3] },
        { position: [11, 4] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 1], ...rectangle(3, 3) },
        { initialPosition: [0, 4], ...rectangle(1, 1), source: "water" },
        { initialPosition: [1, 0], ...rectangle(2, 1) },
        { initialPosition: [1, 4], ...rectangle(2, 1) },
        { initialPosition: [11, 0], ...sink },
        { initialPosition: [11, 4], ...rectangle(1, 1), sink: "water" },
        { initialPosition: [3, 0], ...shortLTopRight },
        { initialPosition: [3, 3], ...shortLBottomLeft },
        { initialPosition: [5, 1], ...rectangle(3, 3) },
      ],
    },
    {
      _id: new ObjectId("66bbca793a67736625ed2c51"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbca793a67736625ed2c45")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 10] },
        { position: [0, 11] },
        { position: [0, 12] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 9] },
        { position: [1, 10] },
        { position: [1, 11] },
        { position: [1, 12] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 8] },
        { position: [2, 9] },
        { position: [2, 10] },
        { position: [2, 11] },
        { position: [2, 12] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5], active: true },
        { position: [3, 7], active: true },
        { position: [3, 8] },
        { position: [3, 9] },
        { position: [3, 10] },
        { position: [3, 11] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5], active: true },
        { position: [4, 6] },
        { position: [4, 7], active: true },
        { position: [4, 8] },
        { position: [4, 9] },
        { position: [4, 10] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5], active: true },
        { position: [5, 6] },
        { position: [5, 7], active: true },
        { position: [5, 8] },
        { position: [5, 9] },
        { position: [6, 4] },
        { position: [6, 5], active: true },
        { position: [6, 6] },
        { position: [6, 7], active: true },
        { position: [6, 8] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5], active: true },
        { position: [7, 6] },
        { position: [7, 7], active: true },
        { position: [7, 8] },
        { position: [7, 9] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5], active: true },
        { position: [8, 6] },
        { position: [8, 7], active: true },
        { position: [8, 8] },
        { position: [8, 9] },
        { position: [8, 10] },
        { position: [9, 1] },
        { position: [9, 2] },
        { position: [9, 3] },
        { position: [9, 4] },
        { position: [9, 5], active: true },
        { position: [9, 7], active: true },
        { position: [9, 8] },
        { position: [9, 9] },
        { position: [9, 10] },
        { position: [9, 11] },
        { position: [10, 0] },
        { position: [10, 1] },
        { position: [10, 2] },
        { position: [10, 3] },
        { position: [10, 4] },
        { position: [10, 8] },
        { position: [10, 9] },
        { position: [10, 10] },
        { position: [10, 11] },
        { position: [10, 12] },
        { position: [11, 0] },
        { position: [11, 1] },
        { position: [11, 2] },
        { position: [11, 3] },
        { position: [11, 9] },
        { position: [11, 10] },
        { position: [11, 11] },
        { position: [11, 12] },
        { position: [12, 0] },
        { position: [12, 1] },
        { position: [12, 2] },
        { position: [12, 10] },
        { position: [12, 11] },
        { position: [12, 12] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 0], ...shortLBottomRight, active: false },
        { initialPosition: [0, 11], ...shortLBottomLeft, active: false },
        { initialPosition: [0, 12], ...rectangle(1, 1), source: "water" },
        { initialPosition: [2, 0], ...shortLTopRight, active: false },
        { initialPosition: [2, 2], ...shortLTopLeft, active: false },
        { initialPosition: [2, 9], ...shortLTopRight, active: false },
        { initialPosition: [2, 11], ...shortLTopLeft, active: false },
        { initialPosition: [4, 5], ...reverseLTopRight },
        { initialPosition: [5, 4], ...lTopLeft },
        { initialPosition: [6, 5], ...rectangle(1, 3) },
        { initialPosition: [6, 6], ...lBottomRight },
        { initialPosition: [7, 5], ...reverseLBottomLeft },
        { initialPosition: [8, 9], ...longTRight },
        { initialPosition: [9, 1], ...rectangle(1, 3) },
        { initialPosition: [10, 1], ...rectangle(3, 1) },
        { initialPosition: [10, 9], ...longTLeft },
        { initialPosition: [10, 10], ...rectangle(1, 1) },
        { initialPosition: [12, 0], ...sink },
        { initialPosition: [12, 12], ...rectangle(1, 1), sink: "water" },
      ],
    },
    {
      _id: new ObjectId("66bbca793a67736625ed2c52"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbca793a67736625ed2c45")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 0], active: true },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6], active: true },
        { position: [3, 0], active: true },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6], active: true },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [9, 0] },
        { position: [9, 1] },
        { position: [9, 2] },
        { position: [9, 3] },
        { position: [9, 4] },
        { position: [9, 5] },
        { position: [9, 6] },
        { position: [10, 0] },
        { position: [10, 1] },
        { position: [10, 2] },
        { position: [10, 3] },
        { position: [10, 4] },
        { position: [10, 5] },
        { position: [10, 6] },
        { position: [11, 0] },
        { position: [11, 1] },
        { position: [11, 2] },
        { position: [11, 3] },
        { position: [11, 4] },
        { position: [11, 5] },
        { position: [11, 6] },
        { position: [12, 0] },
        { position: [12, 1] },
        { position: [12, 2] },
        { position: [12, 3] },
        { position: [12, 4] },
        { position: [12, 5] },
        { position: [12, 6] },
        { position: [13, 0] },
        { position: [13, 1] },
        { position: [13, 2], active: true },
        { position: [13, 3], active: true },
        { position: [13, 4], active: true },
        { position: [13, 5] },
        { position: [13, 6] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 3], ...rectangle(2, 1), weak: true },
        { initialPosition: [0, 6], ...rectangle(1, 1), source: "water" },
        { initialPosition: [1, 0], ...rectangle(2, 1), active: "disactive" },
        { initialPosition: [1, 6], ...rectangle(2, 1), active: "disactive" },
        { initialPosition: [2, 1], ...rectangle(2, 1), active: "disactive" },
        { initialPosition: [2, 3], ...rectangle(2, 1), weak: true },
        { initialPosition: [2, 5], ...rectangle(2, 1), active: "disactive" },
        { initialPosition: [4, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 3], ...rectangle(2, 1), weak: true },
        { initialPosition: [4, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [8, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [8, 3], ...rectangle(2, 1), weak: true },
        { initialPosition: [8, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [9, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [9, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [10, 2], ...rectangle(2, 1), weak: true },
        { initialPosition: [10, 4], ...rectangle(2, 1), weak: true },
        { initialPosition: [11, 0], ...rectangle(2, 1), weak: true },
        { initialPosition: [11, 6], ...rectangle(2, 1), weak: true },
        { initialPosition: [12, 1], ...rectangle(2, 1), weak: true },
        { initialPosition: [12, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [12, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [12, 5], ...rectangle(2, 1), weak: true },
        { initialPosition: [13, 0], ...sink },
        { initialPosition: [13, 6], ...rectangle(1, 1), sink: "water" },
        ...(
          [
            { initialPosition: [6, 3], ...rectangle(1, 1) },
            { initialPosition: [7, 3], ...rectangle(1, 1) },
            { initialPosition: [13, 2], ...rectangle(1, 1) },
            { initialPosition: [13, 4], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, mobile: false, weak: true })),
        ...(
          [
            { initialPosition: [3, 2], ...rectangle(1, 1) },
            { initialPosition: [3, 4], ...rectangle(1, 1) },
            { initialPosition: [10, 0], ...rectangle(1, 1) },
            { initialPosition: [10, 6], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({
          ...block,
          active: "disactive" as const,
          mobile: false,
        })),
      ],
    },
    {
      _id: new ObjectId("66bbca793a67736625ed2c53"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbca793a67736625ed2c45")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3], active: true },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3], active: true },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3], active: true },
        { position: [2, 4] },
        { position: [2, 5], active: true },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5], active: true },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1], active: true },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5], active: true },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1], active: true },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 0] },
        { position: [6, 1], active: true },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [7, 0] },
        { position: [7, 1], active: true },
        { position: [7, 2], active: true },
        { position: [7, 3], active: true },
        { position: [7, 4], active: true },
        { position: [7, 5], active: true },
        { position: [7, 6] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source, mobile: true },
        { initialPosition: [0, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [0, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [1, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [3, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [3, 6], ...sink, mobile: true },
        { initialPosition: [4, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 0], ...rectangle(1, 1), n: 3 },
        { initialPosition: [5, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [8, 0], ...sink, mobile: true },
        ...(
          [
            { initialPosition: [0, 3], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, n: 2, mobile: false })),
        ...(
          [
            { initialPosition: [1, 1], ...rectangle(1, 1) },
            { initialPosition: [3, 3], ...rectangle(1, 1) },
            { initialPosition: [5, 3], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, mobile: false, weak: true })),
        { initialPosition: [0, 6], ...source, source: "water", mobile: true },
        { initialPosition: [8, 6], ...sink, sink: "water", mobile: true },
      ],
    },
    {
      _id: new ObjectId("66bbca793a67736625ed2c54"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbca793a67736625ed2c45")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 3] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2], active: true },
        { position: [3, 3], active: true },
        { position: [3, 4], active: true },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2], active: true },
        { position: [5, 3], active: true },
        { position: [5, 4], active: true },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2], active: true },
        { position: [6, 3] },
        { position: [6, 4], active: true },
        { position: [6, 5], active: true },
        { position: [6, 6] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2], active: true },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5], active: true },
        { position: [7, 6] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [9, 0] },
        { position: [9, 1] },
        { position: [9, 2], active: true },
        { position: [9, 3], active: true },
        { position: [9, 4], active: true },
        { position: [9, 5] },
        { position: [9, 6] },
        { position: [10, 0] },
        { position: [10, 1] },
        { position: [10, 2] },
        { position: [10, 3] },
        { position: [10, 4] },
        { position: [10, 5] },
        { position: [10, 6] },
        { position: [11, 0] },
        { position: [11, 1] },
        { position: [11, 2] },
        { position: [11, 3] },
        { position: [11, 4] },
        { position: [11, 5] },
        { position: [11, 6] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...rectangle(1, 1), n: 4 },
        { initialPosition: [0, 1], ...rectangle(1, 1), n: 2 },
        { initialPosition: [0, 2], ...rectangle(1, 1), n: 2 },
        { initialPosition: [0, 3], ...rectangle(1, 1), n: 2 },
        { initialPosition: [0, 4], ...rectangle(1, 1), n: 2 },
        { initialPosition: [0, 5], ...rectangle(1, 1), n: 2 },
        { initialPosition: [0, 6], ...rectangle(1, 1), n: 4 },
        { initialPosition: [1, 3], ...rectangle(1, 1), n: 2 },
        { initialPosition: [2, 1], ...source },
        { initialPosition: [2, 3], ...rectangle(1, 1), n: 2 },
        { initialPosition: [2, 5], ...rectangle(1, 1), source: "water" },
        { initialPosition: [3, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [3, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [8, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [11, 0], ...sink },
        { initialPosition: [11, 2], ...sink },
        { initialPosition: [11, 4], ...rectangle(1, 1), sink: "water" },
        { initialPosition: [11, 6], ...rectangle(1, 1), sink: "water" },
        ...(
          [
            { initialPosition: [11, 1], ...rectangle(1, 1) },
            { initialPosition: [11, 3], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, n: 2, mobile: false })),
        ...(
          [
            { initialPosition: [10, 6], ...rectangle(1, 1) },
            { initialPosition: [11, 5], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, n: 3, mobile: false })),
        ...(
          [
            { initialPosition: [2, 0], ...rectangle(1, 1) },
            { initialPosition: [2, 6], ...rectangle(1, 1) },
            { initialPosition: [3, 0], ...rectangle(1, 1) },
            { initialPosition: [3, 2], ...rectangle(1, 1) },
            { initialPosition: [3, 4], ...rectangle(1, 1) },
            { initialPosition: [3, 6], ...rectangle(1, 1) },
            { initialPosition: [4, 0], ...rectangle(1, 1) },
            { initialPosition: [4, 1], ...rectangle(1, 1) },
            { initialPosition: [4, 6], ...rectangle(1, 1) },
            { initialPosition: [5, 0], ...rectangle(1, 1) },
            { initialPosition: [5, 2], ...rectangle(1, 1) },
            { initialPosition: [5, 6], ...rectangle(1, 1) },
            { initialPosition: [6, 0], ...rectangle(1, 1) },
            { initialPosition: [6, 6], ...rectangle(1, 1) },
            { initialPosition: [7, 0], ...rectangle(1, 1) },
            { initialPosition: [7, 2], ...rectangle(1, 1) },
            { initialPosition: [7, 6], ...rectangle(1, 1) },
            { initialPosition: [8, 0], ...rectangle(1, 1) },
            { initialPosition: [8, 2], ...rectangle(1, 1) },
            { initialPosition: [8, 6], ...rectangle(1, 1) },
            { initialPosition: [9, 0], ...rectangle(1, 1) },
            { initialPosition: [9, 2], ...rectangle(1, 1) },
            { initialPosition: [9, 4], ...rectangle(1, 1) },
            { initialPosition: [9, 6], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, mobile: false, weak: true })),
      ],
    },
  ],
} satisfies AreaData);
