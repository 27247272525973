import { ObjectId } from "mongodb/lib/bson";

import type { AreaData } from "@sunblocks/game";
import { zArea } from "@sunblocks/game";

import { area1 } from "./area-1";
import { area2 } from "./area-2";
import { area3 } from "./area-3";
import { area4 } from "./area-4";
import { area5 } from "./area-5";
import { area6 } from "./area-6";
import { area7 } from "./area-7";
import { area8 } from "./area-8";
import { area9 } from "./area-9";
import { rectangle, sink, source } from "./shapes";

const level1Ids = area1.levels.map(({ _id }) => _id);
const level2Ids = area2.levels.map(({ _id }) => _id);
const level3Ids = area3.levels.map(({ _id }) => _id);
const level4Ids = area4.levels.map(({ _id }) => _id);
const level5Ids = area5.levels.map(({ _id }) => _id);
const level6Ids = area6.levels.map(({ _id }) => _id);
const level7Ids = area7.levels.map(({ _id }) => _id);
const level8Ids = area8.levels.map(({ _id }) => _id);
const level9Ids = area9.levels.map(({ _id }) => _id);

export const area10 = zArea.parse({
  _id: new ObjectId("66bbcb393a67736625ed2dc5"),
  models: {
    ...area1.models,
    all: { model: { sink: "sun", mobile: true } },
  },
  levels: [
    {
      _id: new ObjectId("66bbcb4e3a67736625ed2dcf"),
      tutorial: true,
      condition: {
        type: "and",
        conditions: [
          {
            type: "levels",
            count: 12,
            levelIds: level9Ids,
          },
          {
            type: "levels",
            levelIds: level1Ids,
          },
        ],
      },
      background: area1.background,
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [0, 7] },
        { position: [0, 8] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [1, 7] },
        { position: [1, 8] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [2, 7] },
        { position: [2, 8] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [3, 7] },
        { position: [3, 8] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [4, 7] },
        { position: [4, 8] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [5, 7] },
        { position: [5, 8] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [6, 7] },
        { position: [6, 8] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [7, 7] },
        { position: [7, 8] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [8, 7] },
        { position: [8, 8] },
        { position: [9, 0] },
        { position: [9, 1] },
        { position: [9, 2] },
        { position: [9, 3] },
        { position: [9, 4] },
        { position: [9, 5] },
        { position: [9, 6] },
        { position: [9, 7] },
        { position: [9, 8] },
        { position: [10, 0] },
        { position: [10, 1] },
        { position: [10, 2] },
        { position: [10, 3] },
        { position: [10, 4] },
        { position: [10, 5] },
        { position: [10, 6] },
        { position: [10, 7] },
        { position: [10, 8] },
        { position: [11, 0] },
        { position: [11, 1] },
        { position: [11, 2] },
        { position: [11, 3] },
        { position: [11, 4] },
        { position: [11, 5] },
        { position: [11, 6] },
        { position: [11, 7] },
        { position: [11, 8] },
        { position: [12, 0] },
        { position: [12, 1] },
        { position: [12, 2] },
        { position: [12, 3] },
        { position: [12, 4] },
        { position: [12, 5] },
        { position: [12, 6] },
        { position: [12, 7] },
        { position: [12, 8] },
      ],
      blocks: [
        { initialPosition: [0, 7], ...source },
        { initialPosition: [1, 2], ...rectangle(1, 1) },
        { initialPosition: [1, 3], ...rectangle(1, 1) },
        { initialPosition: [1, 4], ...rectangle(1, 1) },
        { initialPosition: [1, 5], ...rectangle(1, 1) },
        { initialPosition: [1, 6], ...rectangle(1, 1) },
        { initialPosition: [1, 8], ...rectangle(1, 1) },
        { initialPosition: [2, 1], ...rectangle(1, 1) },
        { initialPosition: [2, 2], ...rectangle(1, 1) },
        { initialPosition: [2, 3], ...rectangle(1, 1) },
        { initialPosition: [2, 4], ...rectangle(1, 1) },
        { initialPosition: [2, 5], ...rectangle(1, 1) },
        { initialPosition: [2, 6], ...rectangle(1, 1) },
        { initialPosition: [2, 7], ...rectangle(1, 1) },
        { initialPosition: [3, 1], ...rectangle(1, 1) },
        { initialPosition: [3, 2], ...rectangle(1, 1) },
        { initialPosition: [3, 3], ...rectangle(1, 1) },
        { initialPosition: [3, 4], ...rectangle(1, 1) },
        { initialPosition: [3, 5], ...rectangle(1, 1) },
        { initialPosition: [3, 6], ...rectangle(1, 1) },
        { initialPosition: [3, 7], ...rectangle(1, 1) },
        { initialPosition: [4, 1], ...rectangle(1, 1) },
        { initialPosition: [4, 2], ...rectangle(1, 1) },
        { initialPosition: [4, 3], ...rectangle(1, 1) },
        { initialPosition: [5, 1], ...rectangle(1, 1) },
        { initialPosition: [5, 2], ...rectangle(1, 1) },
        { initialPosition: [5, 3], ...rectangle(1, 1) },
        { initialPosition: [5, 4], ...rectangle(1, 1) },
        { initialPosition: [5, 5], ...rectangle(1, 1) },
        { initialPosition: [5, 6], ...rectangle(1, 1) },
        { initialPosition: [6, 1], ...rectangle(1, 1) },
        { initialPosition: [6, 2], ...rectangle(1, 1) },
        { initialPosition: [6, 3], ...rectangle(1, 1) },
        { initialPosition: [6, 4], ...rectangle(1, 1) },
        { initialPosition: [6, 5], ...rectangle(1, 1) },
        { initialPosition: [6, 6], ...rectangle(1, 1) },
        { initialPosition: [6, 7], ...rectangle(1, 1) },
        { initialPosition: [7, 2], ...rectangle(1, 1) },
        { initialPosition: [7, 3], ...rectangle(1, 1) },
        { initialPosition: [7, 4], ...rectangle(1, 1) },
        { initialPosition: [7, 5], ...rectangle(1, 1) },
        { initialPosition: [7, 6], ...rectangle(1, 1) },
        { initialPosition: [7, 7], ...rectangle(1, 1) },
        { initialPosition: [8, 5], ...rectangle(1, 1) },
        { initialPosition: [8, 6], ...rectangle(1, 1) },
        { initialPosition: [8, 7], ...rectangle(1, 1) },
        { initialPosition: [9, 1], ...rectangle(1, 1) },
        { initialPosition: [9, 2], ...rectangle(1, 1) },
        { initialPosition: [9, 3], ...rectangle(1, 1) },
        { initialPosition: [9, 4], ...rectangle(1, 1) },
        { initialPosition: [9, 5], ...rectangle(1, 1) },
        { initialPosition: [9, 6], ...rectangle(1, 1) },
        { initialPosition: [9, 7], ...rectangle(1, 1) },
        { initialPosition: [10, 1], ...rectangle(1, 1) },
        { initialPosition: [10, 2], ...rectangle(1, 1) },
        { initialPosition: [10, 3], ...rectangle(1, 1) },
        { initialPosition: [10, 4], ...rectangle(1, 1) },
        { initialPosition: [10, 5], ...rectangle(1, 1) },
        { initialPosition: [10, 6], ...rectangle(1, 1) },
        { initialPosition: [10, 7], ...rectangle(1, 1) },
        { initialPosition: [11, 1], ...sink },
        { initialPosition: [11, 2], ...rectangle(1, 1) },
        { initialPosition: [11, 3], ...rectangle(1, 1) },
        { initialPosition: [11, 4], ...rectangle(1, 1) },
        { initialPosition: [11, 5], ...rectangle(1, 1) },
        { initialPosition: [11, 6], ...rectangle(1, 1) },
      ],
    },
    {
      _id: new ObjectId("66bbcb4e3a67736625ed2dd0"),
      tutorial: true,
      models: area2.models,
      condition: {
        type: "and",
        conditions: [
          {
            type: "levels",
            count: 12,
            levelIds: level9Ids,
          },
          {
            type: "levels",
            levelIds: level2Ids,
          },
        ],
      },
      background: area2.background,
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [0, 7] },
        { position: [0, 8] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [1, 7] },
        { position: [1, 8] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [2, 7] },
        { position: [2, 8] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [3, 7] },
        { position: [3, 8] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [4, 7] },
        { position: [4, 8] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [5, 7] },
        { position: [5, 8] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [6, 7] },
        { position: [6, 8] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [7, 7] },
        { position: [7, 8] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [8, 7] },
        { position: [8, 8] },
        { position: [9, 0] },
        { position: [9, 1] },
        { position: [9, 2] },
        { position: [9, 3] },
        { position: [9, 4] },
        { position: [9, 5] },
        { position: [9, 6] },
        { position: [9, 7] },
        { position: [9, 8] },
        { position: [10, 0] },
        { position: [10, 1] },
        { position: [10, 2] },
        { position: [10, 3] },
        { position: [10, 4] },
        { position: [10, 5] },
        { position: [10, 6] },
        { position: [10, 7] },
        { position: [10, 8] },
        { position: [11, 0] },
        { position: [11, 1] },
        { position: [11, 2] },
        { position: [11, 3] },
        { position: [11, 4] },
        { position: [11, 5] },
        { position: [11, 6] },
        { position: [11, 7] },
        { position: [11, 8] },
        { position: [12, 0] },
        { position: [12, 1] },
        { position: [12, 2] },
        { position: [12, 3] },
        { position: [12, 4] },
        { position: [12, 5] },
        { position: [12, 6] },
        { position: [12, 7] },
        { position: [12, 8] },
      ],
      blocks: [
        { initialPosition: [0, 2], ...rectangle(1, 1) },
        { initialPosition: [1, 1], ...source },
        { initialPosition: [1, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 6], ...sink },
        { initialPosition: [1, 7], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 2], ...rectangle(1, 1) },
        { initialPosition: [2, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 6], ...rectangle(1, 1) },
        { initialPosition: [2, 7], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 2], ...rectangle(1, 1) },
        { initialPosition: [3, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 6], ...rectangle(1, 1) },
        { initialPosition: [3, 7], ...rectangle(1, 1), active: false },
        { initialPosition: [4, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [4, 2], ...rectangle(1, 1) },
        { initialPosition: [4, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [4, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [4, 6], ...rectangle(1, 1) },
        { initialPosition: [4, 7], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 2], ...rectangle(1, 1) },
        { initialPosition: [5, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 6], ...rectangle(1, 1) },
        { initialPosition: [5, 7], ...rectangle(1, 1), active: false },
        { initialPosition: [6, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [6, 2], ...rectangle(1, 1) },
        { initialPosition: [6, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [6, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [6, 6], ...rectangle(1, 1) },
        { initialPosition: [6, 7], ...rectangle(1, 1), active: false },
        { initialPosition: [7, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [7, 2], ...rectangle(1, 1) },
        { initialPosition: [7, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [7, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [7, 6], ...rectangle(1, 1) },
        { initialPosition: [7, 7], ...rectangle(1, 1), active: false },
        { initialPosition: [8, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [8, 2], ...rectangle(1, 1) },
        { initialPosition: [8, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [8, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [8, 6], ...rectangle(1, 1) },
        { initialPosition: [8, 7], ...rectangle(1, 1), active: false },
        { initialPosition: [9, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [9, 2], ...rectangle(1, 1) },
        { initialPosition: [9, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [9, 4], ...rectangle(1, 1), active: false },
        { initialPosition: [9, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [9, 6], ...rectangle(1, 1) },
        { initialPosition: [9, 7], ...rectangle(1, 1), active: false },
        { initialPosition: [10, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [10, 2], ...rectangle(1, 1) },
        { initialPosition: [10, 3], ...rectangle(1, 1) },
        { initialPosition: [10, 4], ...rectangle(1, 1) },
        { initialPosition: [10, 5], ...rectangle(1, 1) },
        { initialPosition: [10, 6], ...rectangle(1, 1) },
        { initialPosition: [10, 7], ...rectangle(1, 1), active: false },
        { initialPosition: [11, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [11, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [11, 4], ...rectangle(1, 1), active: false },
        { initialPosition: [11, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [11, 6], ...rectangle(1, 1), active: false },
      ],
    },
    {
      _id: new ObjectId("66bbcb4e3a67736625ed2dd1"),
      tutorial: true,
      models: area3.models,
      condition: {
        type: "and",
        conditions: [
          {
            type: "levels",
            count: 12,
            levelIds: level9Ids,
          },
          {
            type: "levels",
            levelIds: level3Ids,
          },
        ],
      },
      background: area3.background,
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [0, 7] },
        { position: [0, 8] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [1, 7] },
        { position: [1, 8] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [2, 7] },
        { position: [2, 8] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [3, 7] },
        { position: [3, 8] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [4, 7] },
        { position: [4, 8] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [5, 7] },
        { position: [5, 8] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [6, 7] },
        { position: [6, 8] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [7, 7] },
        { position: [7, 8] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [8, 7] },
        { position: [8, 8] },
        { position: [9, 0] },
        { position: [9, 1] },
        { position: [9, 2] },
        { position: [9, 3] },
        { position: [9, 4] },
        { position: [9, 5] },
        { position: [9, 6] },
        { position: [9, 7] },
        { position: [9, 8] },
        { position: [10, 0] },
        { position: [10, 1] },
        { position: [10, 2] },
        { position: [10, 3] },
        { position: [10, 4] },
        { position: [10, 5] },
        { position: [10, 6] },
        { position: [10, 7] },
        { position: [10, 8] },
        { position: [11, 0] },
        { position: [11, 1] },
        { position: [11, 2] },
        { position: [11, 3] },
        { position: [11, 4] },
        { position: [11, 5] },
        { position: [11, 6] },
        { position: [11, 7] },
        { position: [11, 8] },
        { position: [12, 0] },
        { position: [12, 1] },
        { position: [12, 2] },
        { position: [12, 3] },
        { position: [12, 4] },
        { position: [12, 5] },
        { position: [12, 6] },
        { position: [12, 7] },
        { position: [12, 8] },
      ],
      blocks: [
        { initialPosition: [1, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [1, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [1, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [1, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [1, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [1, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 7], ...rectangle(1, 1), mobile: false },
        { initialPosition: [3, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [3, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [3, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [3, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [3, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [3, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [3, 7], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 7], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 7], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 7], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 7], ...rectangle(1, 1), mobile: false },
        { initialPosition: [8, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [8, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [8, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [8, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [8, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [8, 7], ...rectangle(1, 1), mobile: false },
        { initialPosition: [9, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [9, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [9, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [9, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [9, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [9, 7], ...rectangle(1, 1), mobile: false },
        { initialPosition: [10, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [10, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [10, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [10, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [10, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [10, 7], ...rectangle(1, 1), mobile: false },
        { initialPosition: [11, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [11, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [11, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [11, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [12, 1], ...source, mobile: true },
        { initialPosition: [12, 7], ...sink, mobile: true },
      ],
    },
    {
      _id: new ObjectId("66bbcb4e3a67736625ed2dd2"),
      tutorial: true,
      models: area4.models,
      background: area4.background,
      condition: {
        type: "and",
        conditions: [
          {
            type: "levels",
            count: 12,
            levelIds: level9Ids,
          },
          {
            type: "levels",
            levelIds: level4Ids,
          },
        ],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [0, 7] },
        { position: [0, 8] },
        { position: [1, 0] },
        { position: [1, 1], active: true },
        { position: [1, 2], active: true },
        { position: [1, 3], active: true },
        { position: [1, 4] },
        { position: [1, 5], active: true },
        { position: [1, 6], active: true },
        { position: [1, 7] },
        { position: [1, 8] },
        { position: [2, 0] },
        { position: [2, 1], active: true },
        { position: [2, 2], active: true },
        { position: [2, 3] },
        { position: [2, 4], active: true },
        { position: [2, 5], active: true },
        { position: [2, 6], active: true },
        { position: [2, 7] },
        { position: [2, 8] },
        { position: [3, 0] },
        { position: [3, 1], active: true },
        { position: [3, 2] },
        { position: [3, 3], active: true },
        { position: [3, 4], active: true },
        { position: [3, 5], active: true },
        { position: [3, 6] },
        { position: [3, 7], active: true },
        { position: [3, 8] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2], active: true },
        { position: [4, 3], active: true },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6], active: true },
        { position: [4, 7], active: true },
        { position: [4, 8] },
        { position: [5, 0] },
        { position: [5, 1], active: true },
        { position: [5, 2], active: true },
        { position: [5, 3], active: true },
        { position: [5, 4] },
        { position: [5, 5], active: true },
        { position: [5, 6], active: true },
        { position: [5, 7], active: true },
        { position: [5, 8] },
        { position: [6, 0] },
        { position: [6, 1], active: true },
        { position: [6, 2], active: true },
        { position: [6, 3] },
        { position: [6, 4], active: true },
        { position: [6, 5], active: true },
        { position: [6, 6], active: true },
        { position: [6, 7] },
        { position: [6, 8] },
        { position: [7, 0] },
        { position: [7, 1], active: true },
        { position: [7, 2] },
        { position: [7, 3], active: true },
        { position: [7, 4], active: true },
        { position: [7, 5], active: true },
        { position: [7, 6] },
        { position: [7, 7], active: true },
        { position: [7, 8] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2], active: true },
        { position: [8, 3], active: true },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6], active: true },
        { position: [8, 7], active: true },
        { position: [8, 8] },
        { position: [9, 0] },
        { position: [9, 1], active: true },
        { position: [9, 2], active: true },
        { position: [9, 3], active: true },
        { position: [9, 4] },
        { position: [9, 5], active: true },
        { position: [9, 6], active: true },
        { position: [9, 7], active: true },
        { position: [9, 8] },
        { position: [10, 0] },
        { position: [10, 1], active: true },
        { position: [10, 2], active: true },
        { position: [10, 3] },
        { position: [10, 4], active: true },
        { position: [10, 5], active: true },
        { position: [10, 6], active: true },
        { position: [10, 7] },
        { position: [10, 8] },
        { position: [11, 0] },
        { position: [11, 1], active: true },
        { position: [11, 2] },
        { position: [11, 3], active: true },
        { position: [11, 4], active: true },
        { position: [11, 5], active: true },
        { position: [11, 6] },
        { position: [11, 7] },
        { position: [11, 8] },
        { position: [12, 0] },
        { position: [12, 1] },
        { position: [12, 2] },
        { position: [12, 3] },
        { position: [12, 4] },
        { position: [12, 5] },
        { position: [12, 6] },
        { position: [12, 7] },
        { position: [12, 8] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source, mobile: true },
        { initialPosition: [1, 3], ...rectangle(1, 1) },
        { initialPosition: [1, 4], ...rectangle(1, 1) },
        { initialPosition: [1, 5], ...rectangle(1, 1) },
        { initialPosition: [2, 2], ...rectangle(1, 1) },
        { initialPosition: [2, 3], ...rectangle(1, 1) },
        { initialPosition: [2, 4], ...rectangle(1, 1) },
        { initialPosition: [2, 6], ...rectangle(1, 1) },
        { initialPosition: [2, 7], ...rectangle(1, 1) },
        { initialPosition: [3, 1], ...rectangle(1, 1) },
        { initialPosition: [3, 2], ...rectangle(1, 1) },
        { initialPosition: [3, 3], ...rectangle(1, 1) },
        { initialPosition: [3, 5], ...rectangle(1, 1) },
        { initialPosition: [3, 6], ...rectangle(1, 1) },
        { initialPosition: [3, 7], ...rectangle(1, 1) },
        { initialPosition: [4, 1], ...rectangle(1, 1) },
        { initialPosition: [4, 2], ...rectangle(1, 1) },
        { initialPosition: [4, 5], ...rectangle(1, 1) },
        { initialPosition: [4, 6], ...rectangle(1, 1) },
        { initialPosition: [5, 1], ...rectangle(1, 1) },
        { initialPosition: [5, 3], ...rectangle(1, 1) },
        { initialPosition: [5, 4], ...rectangle(1, 1) },
        { initialPosition: [5, 5], ...rectangle(1, 1) },
        { initialPosition: [5, 7], ...rectangle(1, 1) },
        { initialPosition: [6, 2], ...rectangle(1, 1) },
        { initialPosition: [6, 3], ...rectangle(1, 1) },
        { initialPosition: [6, 4], ...rectangle(1, 1) },
        { initialPosition: [6, 6], ...rectangle(1, 1) },
        { initialPosition: [7, 1], ...rectangle(1, 1) },
        { initialPosition: [7, 2], ...rectangle(1, 1) },
        { initialPosition: [7, 3], ...rectangle(1, 1) },
        { initialPosition: [7, 5], ...rectangle(1, 1) },
        { initialPosition: [7, 6], ...rectangle(1, 1) },
        { initialPosition: [7, 7], ...rectangle(1, 1) },
        { initialPosition: [8, 1], ...rectangle(1, 1) },
        { initialPosition: [8, 2], ...rectangle(1, 1) },
        { initialPosition: [8, 5], ...rectangle(1, 1) },
        { initialPosition: [8, 6], ...rectangle(1, 1) },
        { initialPosition: [9, 1], ...rectangle(1, 1) },
        { initialPosition: [9, 3], ...rectangle(1, 1) },
        { initialPosition: [9, 4], ...rectangle(1, 1) },
        { initialPosition: [9, 5], ...rectangle(1, 1) },
        { initialPosition: [9, 7], ...rectangle(1, 1) },
        { initialPosition: [10, 2], ...rectangle(1, 1) },
        { initialPosition: [10, 3], ...rectangle(1, 1) },
        { initialPosition: [10, 4], ...rectangle(1, 1) },
        { initialPosition: [10, 6], ...sink },
        { initialPosition: [10, 7], ...rectangle(1, 1) },
        { initialPosition: [11, 1], ...rectangle(1, 1) },
        { initialPosition: [11, 2], ...rectangle(1, 1) },
        { initialPosition: [11, 3], ...rectangle(1, 1) },
        { initialPosition: [11, 5], ...rectangle(1, 1) },
        { initialPosition: [11, 6], ...rectangle(1, 1) },
      ],
    },
    {
      _id: new ObjectId("66bbcb4e3a67736625ed2dd3"),
      tutorial: true,
      models: area5.models,
      background: area5.background,
      condition: {
        type: "and",
        conditions: [
          {
            type: "levels",
            count: 12,
            levelIds: level9Ids,
          },
          {
            type: "levels",
            levelIds: level5Ids,
          },
        ],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [0, 7] },
        { position: [0, 8] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [1, 7] },
        { position: [1, 8] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [2, 7] },
        { position: [2, 8] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [3, 7] },
        { position: [3, 8] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [4, 7] },
        { position: [4, 8] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [5, 7] },
        { position: [5, 8] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [6, 7] },
        { position: [6, 8] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [7, 7] },
        { position: [7, 8] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [8, 7] },
        { position: [8, 8] },
        { position: [9, 0] },
        { position: [9, 1] },
        { position: [9, 2] },
        { position: [9, 3] },
        { position: [9, 4] },
        { position: [9, 5] },
        { position: [9, 6] },
        { position: [9, 7] },
        { position: [9, 8] },
        { position: [10, 0] },
        { position: [10, 1] },
        { position: [10, 2] },
        { position: [10, 3] },
        { position: [10, 4] },
        { position: [10, 5] },
        { position: [10, 6] },
        { position: [10, 7] },
        { position: [10, 8] },
        { position: [11, 0] },
        { position: [11, 1] },
        { position: [11, 2] },
        { position: [11, 3] },
        { position: [11, 4] },
        { position: [11, 5] },
        { position: [11, 6] },
        { position: [11, 7] },
        { position: [11, 8] },
        { position: [12, 0] },
        { position: [12, 1] },
        { position: [12, 2] },
        { position: [12, 3] },
        { position: [12, 4] },
        { position: [12, 5] },
        { position: [12, 6] },
        { position: [12, 7] },
        { position: [12, 8] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 2], ...source, n: 4 },
        { initialPosition: [1, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 2], ...rectangle(1, 1), n: 4 },
        { initialPosition: [2, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 2], ...rectangle(1, 1), n: 4 },
        { initialPosition: [3, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [4, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [4, 2], ...rectangle(1, 1), n: 4 },
        { initialPosition: [4, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 2], ...rectangle(1, 1), n: 4 },
        { initialPosition: [5, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [6, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [6, 2], ...rectangle(1, 1), n: 4 },
        { initialPosition: [6, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [7, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [7, 2], ...rectangle(1, 1), n: 4 },
        { initialPosition: [7, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [8, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [8, 2], ...rectangle(1, 1), n: 4 },
        { initialPosition: [8, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [9, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [9, 2], ...rectangle(1, 1), n: 4 },
        { initialPosition: [9, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [9, 4], ...rectangle(1, 1), active: false },
        { initialPosition: [9, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [9, 6], ...rectangle(1, 1), active: false },
        { initialPosition: [9, 7], ...rectangle(1, 1), active: false },
        { initialPosition: [10, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [10, 2], ...rectangle(1, 1), n: 4 },
        { initialPosition: [10, 3], ...rectangle(1, 1), n: 4 },
        { initialPosition: [10, 4], ...rectangle(1, 1), n: 4 },
        { initialPosition: [10, 5], ...rectangle(1, 1), n: 4 },
        { initialPosition: [10, 6], ...rectangle(1, 1), n: 4 },
        { initialPosition: [10, 7], ...sink, n: 4 },
        { initialPosition: [11, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [11, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [11, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [11, 4], ...rectangle(1, 1), active: false },
        { initialPosition: [11, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [11, 6], ...rectangle(1, 1), active: false },
        { initialPosition: [11, 7], ...rectangle(1, 1), active: false },
        { initialPosition: [11, 8], ...rectangle(1, 1), active: false },
      ],
    },
    {
      _id: new ObjectId("66bbcb4e3a67736625ed2dd4"),
      tutorial: true,
      models: area6.models,
      background: area6.background,
      condition: {
        type: "and",
        conditions: [
          {
            type: "levels",
            count: 12,
            levelIds: level9Ids,
          },
          {
            type: "levels",
            levelIds: level6Ids,
          },
        ],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [0, 7] },
        { position: [0, 8] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [1, 7] },
        { position: [1, 8] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [2, 7] },
        { position: [2, 8] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [3, 7] },
        { position: [3, 8] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [4, 7] },
        { position: [4, 8] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [5, 7] },
        { position: [5, 8] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [6, 7] },
        { position: [6, 8] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [7, 7] },
        { position: [7, 8] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [8, 7] },
        { position: [8, 8] },
        { position: [9, 0] },
        { position: [9, 1] },
        { position: [9, 2] },
        { position: [9, 3] },
        { position: [9, 4] },
        { position: [9, 5] },
        { position: [9, 6] },
        { position: [9, 7] },
        { position: [9, 8] },
        { position: [10, 0] },
        { position: [10, 1] },
        { position: [10, 2] },
        { position: [10, 3] },
        { position: [10, 4] },
        { position: [10, 5] },
        { position: [10, 6] },
        { position: [10, 7] },
        { position: [10, 8] },
        { position: [11, 0] },
        { position: [11, 1] },
        { position: [11, 2] },
        { position: [11, 3] },
        { position: [11, 4] },
        { position: [11, 5] },
        { position: [11, 6] },
        { position: [11, 7] },
        { position: [11, 8] },
        { position: [12, 0] },
        { position: [12, 1] },
        { position: [12, 2] },
        { position: [12, 3] },
        { position: [12, 4] },
        { position: [12, 5] },
        { position: [12, 6] },
        { position: [12, 7] },
        { position: [12, 8] },
      ],
      blocks: [
        { initialPosition: [0, 3], ...rectangle(1, 1) },
        { initialPosition: [1, 2], ...source },
        { initialPosition: [1, 4], ...rectangle(1, 1), weak: true },
        { initialPosition: [1, 5], ...rectangle(1, 1) },
        { initialPosition: [1, 6], ...rectangle(1, 1), weak: true },
        { initialPosition: [2, 1], ...rectangle(1, 1), weak: true },
        { initialPosition: [2, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [2, 3], ...rectangle(1, 1), weak: true },
        { initialPosition: [2, 4], ...rectangle(1, 1), weak: true },
        { initialPosition: [2, 5], ...rectangle(1, 1), weak: true },
        { initialPosition: [2, 6], ...rectangle(1, 1), weak: true },
        { initialPosition: [2, 7], ...rectangle(1, 1), weak: true },
        { initialPosition: [3, 1], ...rectangle(1, 1) },
        { initialPosition: [3, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [3, 3], ...rectangle(1, 1) },
        { initialPosition: [3, 4], ...rectangle(1, 1), weak: true },
        { initialPosition: [3, 5], ...rectangle(1, 1) },
        { initialPosition: [3, 6], ...rectangle(1, 1), weak: true },
        { initialPosition: [3, 7], ...rectangle(1, 1) },
        { initialPosition: [4, 1], ...rectangle(1, 1), weak: true },
        { initialPosition: [4, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [4, 3], ...rectangle(1, 1), weak: true },
        { initialPosition: [4, 5], ...rectangle(1, 1), weak: true },
        { initialPosition: [4, 6], ...rectangle(1, 1), weak: true },
        { initialPosition: [4, 7], ...rectangle(1, 1), weak: true },
        { initialPosition: [5, 1], ...rectangle(1, 1) },
        { initialPosition: [5, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [5, 3], ...rectangle(1, 1) },
        { initialPosition: [5, 5], ...rectangle(1, 1) },
        { initialPosition: [5, 6], ...rectangle(1, 1), weak: true },
        { initialPosition: [5, 7], ...rectangle(1, 1) },
        { initialPosition: [6, 1], ...rectangle(1, 1), weak: true },
        { initialPosition: [6, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [6, 3], ...rectangle(1, 1), weak: true },
        { initialPosition: [6, 5], ...rectangle(1, 1), weak: true },
        { initialPosition: [6, 6], ...rectangle(1, 1), weak: true },
        { initialPosition: [6, 7], ...rectangle(1, 1), weak: true },
        { initialPosition: [7, 1], ...rectangle(1, 1) },
        { initialPosition: [7, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [7, 3], ...rectangle(1, 1) },
        { initialPosition: [7, 5], ...rectangle(1, 1) },
        { initialPosition: [7, 6], ...rectangle(1, 1), weak: true },
        { initialPosition: [7, 7], ...rectangle(1, 1) },
        { initialPosition: [8, 1], ...rectangle(1, 1), weak: true },
        { initialPosition: [8, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [8, 3], ...rectangle(1, 1), weak: true },
        { initialPosition: [8, 5], ...rectangle(1, 1), weak: true },
        { initialPosition: [8, 6], ...rectangle(1, 1), weak: true },
        { initialPosition: [8, 7], ...rectangle(1, 1), weak: true },
        { initialPosition: [9, 1], ...rectangle(1, 1) },
        { initialPosition: [9, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [9, 3], ...rectangle(1, 1) },
        { initialPosition: [9, 4], ...rectangle(1, 1), weak: true },
        { initialPosition: [9, 5], ...rectangle(1, 1) },
        { initialPosition: [9, 6], ...rectangle(1, 1), weak: true },
        { initialPosition: [9, 7], ...rectangle(1, 1) },
        { initialPosition: [10, 1], ...rectangle(1, 1), weak: true },
        { initialPosition: [10, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [10, 3], ...rectangle(1, 1), weak: true },
        { initialPosition: [10, 4], ...rectangle(1, 1), weak: true },
        { initialPosition: [10, 5], ...rectangle(1, 1), weak: true },
        { initialPosition: [10, 6], ...rectangle(1, 1), weak: true },
        { initialPosition: [10, 7], ...rectangle(1, 1), weak: true },
        { initialPosition: [11, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [11, 3], ...rectangle(1, 1) },
        { initialPosition: [11, 4], ...rectangle(1, 1), weak: true },
        { initialPosition: [11, 5], ...rectangle(1, 1) },
        { initialPosition: [11, 6], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbcb4e3a67736625ed2dd5"),
      tutorial: true,
      models: area7.models,
      background: area7.background,
      condition: {
        type: "and",
        conditions: [
          {
            type: "levels",
            count: 12,
            levelIds: level9Ids,
          },
          {
            type: "levels",
            levelIds: level7Ids,
          },
        ],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [0, 7] },
        { position: [0, 8] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [1, 7] },
        { position: [1, 8] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [2, 7] },
        { position: [2, 8] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [3, 7] },
        { position: [3, 8] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [4, 7] },
        { position: [4, 8] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [5, 7] },
        { position: [5, 8] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [6, 7] },
        { position: [6, 8] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [7, 7] },
        { position: [7, 8] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [8, 7] },
        { position: [8, 8] },
        { position: [9, 0] },
        { position: [9, 1] },
        { position: [9, 2] },
        { position: [9, 3] },
        { position: [9, 4] },
        { position: [9, 5] },
        { position: [9, 6] },
        { position: [9, 7] },
        { position: [9, 8] },
        { position: [10, 0] },
        { position: [10, 1] },
        { position: [10, 2] },
        { position: [10, 3] },
        { position: [10, 4] },
        { position: [10, 5] },
        { position: [10, 6] },
        { position: [10, 7] },
        { position: [10, 8] },
        { position: [11, 0] },
        { position: [11, 1] },
        { position: [11, 2] },
        { position: [11, 3] },
        { position: [11, 4] },
        { position: [11, 5] },
        { position: [11, 6] },
        { position: [11, 7] },
        { position: [11, 8] },
        { position: [12, 0] },
        { position: [12, 1] },
        { position: [12, 2] },
        { position: [12, 3] },
        { position: [12, 4] },
        { position: [12, 5] },
        { position: [12, 6] },
        { position: [12, 7] },
        { position: [12, 8] },
      ],
      blocks: [
        { initialPosition: [1, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [1, 3], ...rectangle(1, 1) },
        { initialPosition: [1, 4], ...rectangle(1, 1), weak: true },
        { initialPosition: [1, 5], ...rectangle(1, 1) },
        { initialPosition: [1, 6], ...rectangle(1, 1), weak: true },
        { initialPosition: [2, 1], ...rectangle(1, 1), weak: true },
        { initialPosition: [2, 2], ...rectangle(1, 1) },
        { initialPosition: [2, 3], ...rectangle(1, 1), weak: true },
        { initialPosition: [2, 4], ...rectangle(1, 1), weak: true },
        { initialPosition: [2, 5], ...rectangle(1, 1), weak: true },
        { initialPosition: [2, 6], ...rectangle(1, 1) },
        { initialPosition: [2, 7], ...rectangle(1, 1), weak: true },
        { initialPosition: [3, 1], ...rectangle(1, 1) },
        { initialPosition: [3, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [3, 3], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [3, 4], ...rectangle(1, 1), weak: true },
        { initialPosition: [3, 5], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [3, 6], ...rectangle(1, 1), weak: true },
        { initialPosition: [3, 7], ...rectangle(1, 1) },
        { initialPosition: [4, 1], ...rectangle(1, 1), weak: true },
        { initialPosition: [4, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [4, 3], ...rectangle(1, 1), weak: true },
        { initialPosition: [4, 5], ...rectangle(1, 1), weak: true },
        { initialPosition: [4, 6], ...rectangle(1, 1), weak: true },
        { initialPosition: [4, 7], ...rectangle(1, 1), weak: true },
        { initialPosition: [5, 1], ...rectangle(1, 1) },
        { initialPosition: [5, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [5, 3], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [5, 5], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [5, 6], ...source },
        { initialPosition: [5, 8], ...rectangle(1, 1) },
        { initialPosition: [6, 1], ...rectangle(1, 1), weak: true },
        { initialPosition: [6, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [6, 3], ...rectangle(1, 1), weak: true },
        { initialPosition: [7, 1], ...rectangle(1, 1) },
        { initialPosition: [7, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [7, 3], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [7, 5], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [7, 6], ...sink },
        { initialPosition: [7, 7], ...rectangle(1, 1) },
        { initialPosition: [8, 1], ...rectangle(1, 1), weak: true },
        { initialPosition: [8, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [8, 3], ...rectangle(1, 1), weak: true },
        { initialPosition: [8, 5], ...rectangle(1, 1), weak: true },
        { initialPosition: [8, 6], ...rectangle(1, 1), weak: true },
        { initialPosition: [8, 7], ...rectangle(1, 1), weak: true },
        { initialPosition: [9, 1], ...rectangle(1, 1) },
        { initialPosition: [9, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [9, 3], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [9, 4], ...rectangle(1, 1), weak: true },
        { initialPosition: [9, 5], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [9, 6], ...rectangle(1, 1), weak: true },
        { initialPosition: [9, 7], ...rectangle(1, 1) },
        { initialPosition: [10, 1], ...rectangle(1, 1), weak: true },
        { initialPosition: [10, 2], ...rectangle(1, 1) },
        { initialPosition: [10, 3], ...rectangle(1, 1), weak: true },
        { initialPosition: [10, 4], ...rectangle(1, 1), weak: true },
        { initialPosition: [10, 5], ...rectangle(1, 1), weak: true },
        { initialPosition: [10, 6], ...rectangle(1, 1) },
        { initialPosition: [10, 7], ...rectangle(1, 1), weak: true },
        { initialPosition: [11, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [11, 3], ...rectangle(1, 1) },
        { initialPosition: [11, 4], ...rectangle(1, 1), weak: true },
        { initialPosition: [11, 5], ...rectangle(1, 1) },
        { initialPosition: [11, 6], ...rectangle(1, 1), weak: true },
      ],
    },
    {
      _id: new ObjectId("66bbcb4e3a67736625ed2dd6"),
      tutorial: true,
      models: {
        ...area8.models,
        all: { model: { sink: "water", mobile: true } },
      },
      background: area8.background,
      condition: {
        type: "and",
        conditions: [
          {
            type: "levels",
            count: 12,
            levelIds: level9Ids,
          },
          {
            type: "levels",
            levelIds: level8Ids,
          },
        ],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [0, 7] },
        { position: [0, 8] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [1, 7] },
        { position: [1, 8] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [2, 7] },
        { position: [2, 8] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [3, 7] },
        { position: [3, 8] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [4, 7] },
        { position: [4, 8] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [5, 7] },
        { position: [5, 8] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [6, 7] },
        { position: [6, 8] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [7, 7] },
        { position: [7, 8] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [8, 7] },
        { position: [8, 8] },
        { position: [9, 0] },
        { position: [9, 1] },
        { position: [9, 2] },
        { position: [9, 3] },
        { position: [9, 4] },
        { position: [9, 5] },
        { position: [9, 6] },
        { position: [9, 7] },
        { position: [9, 8] },
        { position: [10, 0] },
        { position: [10, 1] },
        { position: [10, 2] },
        { position: [10, 3] },
        { position: [10, 4] },
        { position: [10, 5] },
        { position: [10, 6] },
        { position: [10, 7] },
        { position: [10, 8] },
        { position: [11, 0] },
        { position: [11, 1] },
        { position: [11, 2] },
        { position: [11, 3] },
        { position: [11, 4] },
        { position: [11, 5] },
        { position: [11, 6] },
        { position: [11, 7] },
        { position: [11, 8] },
        { position: [12, 0] },
        { position: [12, 1] },
        { position: [12, 2] },
        { position: [12, 3] },
        { position: [12, 4] },
        { position: [12, 5] },
        { position: [12, 6] },
        { position: [12, 7] },
        { position: [12, 8] },
      ],
      blocks: [
        { initialPosition: [1, 1], ...rectangle(1, 1) },
        { initialPosition: [1, 2], ...rectangle(1, 1) },
        { initialPosition: [1, 3], ...rectangle(1, 1) },
        { initialPosition: [1, 5], ...rectangle(1, 1) },
        { initialPosition: [1, 6], ...rectangle(1, 1) },
        { initialPosition: [1, 7], ...rectangle(1, 1) },
        { initialPosition: [2, 1], ...rectangle(1, 1) },
        { initialPosition: [2, 2], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [2, 3], ...rectangle(1, 1) },
        { initialPosition: [2, 5], ...rectangle(1, 1) },
        { initialPosition: [2, 6], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [2, 7], ...rectangle(1, 1) },
        { initialPosition: [3, 1], ...rectangle(1, 1) },
        { initialPosition: [3, 2], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [3, 3], ...rectangle(1, 1) },
        { initialPosition: [3, 5], ...rectangle(1, 1) },
        { initialPosition: [3, 6], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [3, 7], ...rectangle(1, 1) },
        { initialPosition: [4, 1], ...rectangle(1, 1) },
        { initialPosition: [4, 2], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [4, 3], ...rectangle(1, 1) },
        { initialPosition: [4, 5], ...rectangle(1, 1) },
        { initialPosition: [4, 6], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [4, 7], ...rectangle(1, 1) },
        { initialPosition: [5, 1], ...source, n: 3 },
        { initialPosition: [5, 2], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [5, 3], ...rectangle(1, 1) },
        { initialPosition: [5, 4], ...rectangle(1, 1) },
        { initialPosition: [5, 5], ...rectangle(1, 1) },
        { initialPosition: [5, 6], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [5, 7], ...sink },
        { initialPosition: [6, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [6, 2], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [6, 3], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [6, 4], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [6, 5], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [7, 1], ...source, source: "water", n: 3 },
        { initialPosition: [7, 2], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [7, 3], ...rectangle(1, 1) },
        { initialPosition: [7, 4], ...rectangle(1, 1) },
        { initialPosition: [7, 5], ...rectangle(1, 1) },
        { initialPosition: [7, 6], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [7, 7], ...sink, sink: "water" },
        { initialPosition: [8, 1], ...rectangle(1, 1) },
        { initialPosition: [8, 2], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [8, 3], ...rectangle(1, 1) },
        { initialPosition: [8, 5], ...rectangle(1, 1) },
        { initialPosition: [8, 6], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [8, 7], ...rectangle(1, 1) },
        { initialPosition: [9, 1], ...rectangle(1, 1) },
        { initialPosition: [9, 2], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [9, 3], ...rectangle(1, 1) },
        { initialPosition: [9, 5], ...rectangle(1, 1) },
        { initialPosition: [9, 6], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [9, 7], ...rectangle(1, 1) },
        { initialPosition: [10, 1], ...rectangle(1, 1) },
        { initialPosition: [10, 2], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [10, 3], ...rectangle(1, 1) },
        { initialPosition: [10, 5], ...rectangle(1, 1) },
        { initialPosition: [10, 6], ...rectangle(1, 1), active: "disactive" },
        { initialPosition: [10, 7], ...rectangle(1, 1) },
        { initialPosition: [11, 1], ...rectangle(1, 1) },
        { initialPosition: [11, 2], ...rectangle(1, 1) },
        { initialPosition: [11, 3], ...rectangle(1, 1) },
        { initialPosition: [11, 5], ...rectangle(1, 1) },
        { initialPosition: [11, 6], ...rectangle(1, 1) },
        { initialPosition: [11, 7], ...rectangle(1, 1) },
      ],
    },
    {
      _id: new ObjectId("66bbcb4e3a67736625ed2dd7"),
      tutorial: true,
      models: {
        ...area9.models,
        all: { model: { sink: "unfilled", mobile: true } },
        available: { fill: "sun" },
      },
      background: area9.background,
      condition: {
        type: "levels",
        levelIds: level9Ids,
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [0, 7] },
        { position: [0, 8] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [1, 7] },
        { position: [1, 8] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [2, 7] },
        { position: [2, 8] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [3, 7] },
        { position: [3, 8] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [4, 7] },
        { position: [4, 8] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [5, 7] },
        { position: [5, 8] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [6, 7] },
        { position: [6, 8] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [7, 7] },
        { position: [7, 8] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [8, 7] },
        { position: [8, 8] },
        { position: [9, 0] },
        { position: [9, 1] },
        { position: [9, 2] },
        { position: [9, 3] },
        { position: [9, 4] },
        { position: [9, 5] },
        { position: [9, 6] },
        { position: [9, 7] },
        { position: [9, 8] },
        { position: [10, 0] },
        { position: [10, 1] },
        { position: [10, 2] },
        { position: [10, 3] },
        { position: [10, 4] },
        { position: [10, 5] },
        { position: [10, 6] },
        { position: [10, 7] },
        { position: [10, 8] },
        { position: [11, 0] },
        { position: [11, 1] },
        { position: [11, 2] },
        { position: [11, 3] },
        { position: [11, 4] },
        { position: [11, 5] },
        { position: [11, 6] },
        { position: [11, 7] },
        { position: [11, 8] },
        { position: [12, 0] },
        { position: [12, 1] },
        { position: [12, 2] },
        { position: [12, 3] },
        { position: [12, 4] },
        { position: [12, 5] },
        { position: [12, 6] },
        { position: [12, 7] },
        { position: [12, 8] },
      ],
      blocks: [
        { initialPosition: [0, 7], ...source, mobile: true },
        { initialPosition: [0, 8], ...sink, sink: "unfilled" },
        { initialPosition: [1, 2], ...rectangle(1, 1) },
        { initialPosition: [1, 3], ...rectangle(1, 1) },
        { initialPosition: [1, 4], ...rectangle(1, 1) },
        { initialPosition: [1, 5], ...rectangle(1, 1) },
        { initialPosition: [1, 6], ...rectangle(1, 1) },
        { initialPosition: [2, 1], ...rectangle(1, 1) },
        { initialPosition: [2, 2], ...rectangle(1, 1) },
        { initialPosition: [2, 3], ...rectangle(1, 1) },
        { initialPosition: [2, 4], ...rectangle(1, 1) },
        { initialPosition: [2, 5], ...rectangle(1, 1) },
        { initialPosition: [2, 6], ...rectangle(1, 1) },
        { initialPosition: [2, 7], ...rectangle(1, 1) },
        { initialPosition: [3, 1], ...rectangle(1, 1) },
        { initialPosition: [3, 2], ...rectangle(1, 1) },
        { initialPosition: [3, 3], ...rectangle(1, 1) },
        { initialPosition: [3, 4], ...rectangle(1, 1) },
        { initialPosition: [3, 5], ...rectangle(1, 1) },
        { initialPosition: [3, 6], ...rectangle(1, 1) },
        { initialPosition: [3, 7], ...rectangle(1, 1) },
        { initialPosition: [4, 1], ...rectangle(1, 1) },
        { initialPosition: [4, 2], ...rectangle(1, 1) },
        { initialPosition: [4, 3], ...rectangle(1, 1) },
        { initialPosition: [5, 1], ...rectangle(1, 1) },
        { initialPosition: [5, 2], ...rectangle(1, 1) },
        { initialPosition: [5, 3], ...rectangle(1, 1) },
        { initialPosition: [5, 4], ...rectangle(1, 1) },
        { initialPosition: [5, 5], ...rectangle(1, 1) },
        { initialPosition: [5, 6], ...rectangle(1, 1) },
        { initialPosition: [6, 1], ...rectangle(1, 1) },
        { initialPosition: [6, 2], ...rectangle(1, 1) },
        { initialPosition: [6, 3], ...rectangle(1, 1) },
        { initialPosition: [6, 4], ...rectangle(1, 1) },
        { initialPosition: [6, 5], ...rectangle(1, 1) },
        { initialPosition: [6, 6], ...rectangle(1, 1) },
        { initialPosition: [6, 7], ...rectangle(1, 1) },
        { initialPosition: [7, 2], ...rectangle(1, 1) },
        { initialPosition: [7, 3], ...rectangle(1, 1) },
        { initialPosition: [7, 4], ...rectangle(1, 1) },
        { initialPosition: [7, 5], ...rectangle(1, 1) },
        { initialPosition: [7, 6], ...rectangle(1, 1) },
        { initialPosition: [7, 7], ...rectangle(1, 1) },
        { initialPosition: [8, 5], ...rectangle(1, 1) },
        { initialPosition: [8, 6], ...rectangle(1, 1) },
        { initialPosition: [8, 7], ...rectangle(1, 1) },
        { initialPosition: [9, 1], ...rectangle(1, 1) },
        { initialPosition: [9, 2], ...rectangle(1, 1) },
        { initialPosition: [9, 3], ...rectangle(1, 1) },
        { initialPosition: [9, 4], ...rectangle(1, 1) },
        { initialPosition: [9, 5], ...rectangle(1, 1) },
        { initialPosition: [9, 6], ...rectangle(1, 1) },
        { initialPosition: [9, 7], ...rectangle(1, 1) },
        { initialPosition: [10, 1], ...rectangle(1, 1) },
        { initialPosition: [10, 2], ...rectangle(1, 1) },
        { initialPosition: [10, 3], ...rectangle(1, 1) },
        { initialPosition: [10, 4], ...rectangle(1, 1) },
        { initialPosition: [10, 5], ...rectangle(1, 1) },
        { initialPosition: [10, 6], ...rectangle(1, 1) },
        { initialPosition: [10, 7], ...rectangle(1, 1) },
        { initialPosition: [11, 1], ...sink },
        { initialPosition: [11, 2], ...rectangle(1, 1) },
        { initialPosition: [11, 3], ...rectangle(1, 1) },
        { initialPosition: [11, 4], ...rectangle(1, 1) },
        { initialPosition: [11, 5], ...rectangle(1, 1) },
        { initialPosition: [11, 6], ...rectangle(1, 1) },
      ],
    },
    {
      _id: new ObjectId("66bbcb4e3a67736625ed2dd8"),
      tutorial: true,
      condition: {
        type: "levels",
        levelIds: [
          new ObjectId("66bbcb4e3a67736625ed2dcf"),
          new ObjectId("66bbcb4e3a67736625ed2dd0"),
          new ObjectId("66bbcb4e3a67736625ed2dd1"),
          new ObjectId("66bbcb4e3a67736625ed2dd2"),
          new ObjectId("66bbcb4e3a67736625ed2dd3"),
          new ObjectId("66bbcb4e3a67736625ed2dd4"),
          new ObjectId("66bbcb4e3a67736625ed2dd5"),
          new ObjectId("66bbcb4e3a67736625ed2dd6"),
          new ObjectId("66bbcb4e3a67736625ed2dd7"),
        ],
      },
      cells: [
        { position: [0, 0] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [1, 7] },
        { position: [1, 8] },
        { position: [1, 9] },
        { position: [1, 10] },
        { position: [1, 11] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [2, 7] },
        { position: [2, 8] },
        { position: [2, 9] },
        { position: [2, 10] },
        { position: [2, 11] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [3, 7] },
        { position: [3, 8] },
        { position: [3, 9] },
        { position: [3, 10] },
        { position: [3, 11] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [4, 7] },
        { position: [4, 8] },
        { position: [4, 9] },
        { position: [4, 10] },
        { position: [4, 11] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [5, 7] },
        { position: [5, 8] },
        { position: [5, 9] },
        { position: [5, 10] },
        { position: [5, 11] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [6, 7] },
        { position: [6, 8] },
        { position: [6, 9] },
        { position: [6, 10] },
        { position: [6, 11] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [7, 7] },
        { position: [7, 8] },
        { position: [7, 9] },
        { position: [7, 10] },
        { position: [7, 11] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [8, 7] },
        { position: [8, 8] },
        { position: [8, 9] },
        { position: [8, 10] },
        { position: [8, 11] },
        { position: [9, 0] },
        { position: [9, 1] },
        { position: [9, 2] },
        { position: [9, 3] },
        { position: [9, 4] },
        { position: [9, 5] },
        { position: [9, 6] },
        { position: [9, 7] },
        { position: [9, 8] },
        { position: [9, 9] },
        { position: [9, 10] },
        { position: [9, 11] },
        { position: [10, 0] },
        { position: [10, 1] },
        { position: [10, 2] },
        { position: [10, 3] },
        { position: [10, 4] },
        { position: [10, 5] },
        { position: [10, 6] },
        { position: [10, 7] },
        { position: [10, 8] },
        { position: [10, 9] },
        { position: [10, 10] },
        { position: [10, 11] },
        { position: [11, 0] },
        { position: [11, 1] },
        { position: [11, 2] },
        { position: [11, 3] },
        { position: [11, 4] },
        { position: [11, 5] },
        { position: [11, 6] },
        { position: [11, 7] },
        { position: [11, 8] },
        { position: [11, 9] },
        { position: [11, 10] },
        { position: [11, 11] },
        { position: [12, 0] },
        { position: [12, 1] },
        { position: [12, 2] },
        { position: [12, 3] },
        { position: [12, 4] },
        { position: [12, 5] },
        { position: [12, 6] },
        { position: [12, 7] },
        { position: [12, 8] },
        { position: [12, 9] },
        { position: [12, 10] },
        { position: [12, 11] },
        { position: [13, 0] },
        { position: [13, 1] },
        { position: [13, 2] },
        { position: [13, 3] },
        { position: [13, 4] },
        { position: [13, 5] },
        { position: [13, 6] },
        { position: [13, 7] },
        { position: [13, 8] },
        { position: [13, 9] },
        { position: [13, 10] },
        { position: [13, 11] },
        { position: [14, 0] },
        { position: [14, 1] },
        { position: [14, 2] },
        { position: [14, 3] },
        { position: [14, 4] },
        { position: [14, 5] },
        { position: [14, 6] },
        { position: [14, 7] },
        { position: [14, 8] },
        { position: [14, 9] },
        { position: [14, 10] },
        { position: [14, 11] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source, mobile: true },
        { initialPosition: [1, 1], ...rectangle(1, 1) },
        { initialPosition: [1, 2], ...rectangle(1, 1) },
        { initialPosition: [1, 3], ...rectangle(1, 1) },
        { initialPosition: [1, 4], ...rectangle(1, 1) },
        { initialPosition: [1, 5], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [1, 6], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [1, 7], ...rectangle(1, 1) },
        { initialPosition: [1, 8], ...rectangle(1, 1) },
        { initialPosition: [1, 9], ...rectangle(1, 1) },
        { initialPosition: [1, 10], ...rectangle(1, 1) },
        { initialPosition: [1, 11], ...rectangle(1, 1) },
        { initialPosition: [2, 0], ...rectangle(1, 1) },
        { initialPosition: [2, 1], ...rectangle(1, 1) },
        { initialPosition: [2, 2], ...rectangle(1, 1) },
        { initialPosition: [2, 3], ...rectangle(1, 1) },
        { initialPosition: [2, 4], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [2, 5], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [2, 6], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [2, 7], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [2, 8], ...rectangle(1, 1) },
        { initialPosition: [2, 9], ...rectangle(1, 1) },
        { initialPosition: [2, 10], ...rectangle(1, 1) },
        { initialPosition: [2, 11], ...rectangle(1, 1) },
        { initialPosition: [3, 0], ...rectangle(1, 1) },
        { initialPosition: [3, 1], ...rectangle(1, 1) },
        { initialPosition: [3, 2], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [3, 3], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [3, 4], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [3, 5], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [3, 6], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [3, 7], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [3, 8], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [3, 9], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [3, 10], ...rectangle(1, 1) },
        { initialPosition: [3, 11], ...rectangle(1, 1) },
        { initialPosition: [4, 0], ...rectangle(1, 1) },
        { initialPosition: [4, 1], ...rectangle(1, 1) },
        { initialPosition: [4, 2], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [4, 3], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [4, 4], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [4, 5], ...rectangle(1, 1) },
        { initialPosition: [4, 6], ...rectangle(1, 1) },
        { initialPosition: [4, 7], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [4, 8], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [4, 9], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [4, 10], ...rectangle(1, 1) },
        { initialPosition: [4, 11], ...rectangle(1, 1) },
        { initialPosition: [5, 0], ...rectangle(1, 1) },
        { initialPosition: [5, 1], ...rectangle(1, 1) },
        { initialPosition: [5, 2], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [5, 3], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [5, 4], ...rectangle(1, 1) },
        { initialPosition: [5, 5], ...rectangle(1, 1) },
        { initialPosition: [5, 6], ...rectangle(1, 1) },
        { initialPosition: [5, 7], ...rectangle(1, 1) },
        { initialPosition: [5, 8], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [5, 9], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [5, 10], ...rectangle(1, 1) },
        { initialPosition: [5, 11], ...rectangle(1, 1) },
        { initialPosition: [6, 0], ...rectangle(1, 1) },
        { initialPosition: [6, 1], ...rectangle(1, 1) },
        { initialPosition: [6, 2], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [6, 3], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [6, 4], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [6, 5], ...rectangle(1, 1) },
        { initialPosition: [6, 6], ...rectangle(1, 1) },
        { initialPosition: [6, 7], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [6, 8], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [6, 9], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [6, 10], ...rectangle(1, 1) },
        { initialPosition: [6, 11], ...rectangle(1, 1) },
        { initialPosition: [7, 0], ...rectangle(1, 1) },
        { initialPosition: [7, 1], ...rectangle(1, 1) },
        { initialPosition: [7, 2], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [7, 3], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [7, 4], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [7, 5], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [7, 6], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [7, 7], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [7, 8], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [7, 9], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [7, 10], ...rectangle(1, 1) },
        { initialPosition: [7, 11], ...rectangle(1, 1) },
        { initialPosition: [8, 0], ...rectangle(1, 1) },
        { initialPosition: [8, 1], ...rectangle(1, 1) },
        { initialPosition: [8, 2], ...rectangle(1, 1) },
        { initialPosition: [8, 3], ...rectangle(1, 1) },
        { initialPosition: [8, 4], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [8, 5], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [8, 6], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [8, 7], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [8, 8], ...rectangle(1, 1) },
        { initialPosition: [8, 9], ...rectangle(1, 1) },
        { initialPosition: [8, 10], ...rectangle(1, 1) },
        { initialPosition: [8, 11], ...rectangle(1, 1) },
        { initialPosition: [9, 0], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [9, 1], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [9, 2], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [9, 3], ...rectangle(1, 1) },
        { initialPosition: [9, 4], ...rectangle(1, 1) },
        { initialPosition: [9, 5], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [9, 6], ...rectangle(1, 1), sunColor: "#dc2626" },
        { initialPosition: [9, 7], ...rectangle(1, 1) },
        { initialPosition: [9, 8], ...rectangle(1, 1) },
        { initialPosition: [9, 9], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [9, 10], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [9, 11], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [10, 0], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [10, 1], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [10, 2], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [10, 3], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [10, 4], ...rectangle(1, 1) },
        { initialPosition: [10, 5], ...rectangle(1, 1) },
        { initialPosition: [10, 6], ...rectangle(1, 1) },
        { initialPosition: [10, 7], ...rectangle(1, 1) },
        { initialPosition: [10, 8], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [10, 9], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [10, 10], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [10, 11], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [11, 0], ...rectangle(1, 1) },
        { initialPosition: [11, 1], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [11, 2], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [11, 3], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [11, 4], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [11, 5], ...rectangle(1, 1) },
        { initialPosition: [11, 6], ...rectangle(1, 1) },
        { initialPosition: [11, 7], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [11, 8], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [11, 9], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [11, 10], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [11, 11], ...rectangle(1, 1) },
        { initialPosition: [12, 0], ...rectangle(1, 1) },
        { initialPosition: [12, 1], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [12, 2], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [12, 3], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [12, 4], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [12, 5], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [12, 6], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [12, 7], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [12, 8], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [12, 9], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [12, 10], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [12, 11], ...rectangle(1, 1) },
        { initialPosition: [13, 0], ...rectangle(1, 1) },
        { initialPosition: [13, 1], ...rectangle(1, 1) },
        { initialPosition: [13, 2], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [13, 3], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [13, 4], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [13, 5], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [13, 6], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [13, 7], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [13, 8], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [13, 9], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [13, 10], ...rectangle(1, 1) },
        { initialPosition: [13, 11], ...rectangle(1, 1) },
        { initialPosition: [14, 0], ...rectangle(1, 1) },
        { initialPosition: [14, 1], ...rectangle(1, 1) },
        { initialPosition: [14, 2], ...rectangle(1, 1) },
        { initialPosition: [14, 3], ...rectangle(1, 1) },
        { initialPosition: [14, 4], ...rectangle(1, 1) },
        { initialPosition: [14, 5], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [14, 6], ...rectangle(1, 1), sunColor: "#15803d" },
        { initialPosition: [14, 7], ...rectangle(1, 1) },
        { initialPosition: [14, 8], ...rectangle(1, 1) },
        { initialPosition: [14, 9], ...rectangle(1, 1) },
        { initialPosition: [14, 10], ...rectangle(1, 1) },
        { initialPosition: [14, 11], ...sink },
      ],
    },
  ],
} satisfies AreaData);
