import { ObjectId } from "mongodb/lib/bson";

import type { AreaData, BlockData } from "@sunblocks/game";
import { zArea } from "@sunblocks/game";

import { area4 } from "./area-4";
import {
  lBottomLeft,
  lBottomRight,
  longLTopLeft,
  plus,
  rectangle,
  reverseLTopLeft,
  shortLBottomLeft,
  shortLBottomRight,
  shortLTopLeft,
  shortLTopRight,
  sink,
  source,
  tDown,
  tLeft,
  tRight,
} from "./shapes";

const level4Ids = area4.levels.map(({ _id }) => _id);

export const area5 = zArea.parse({
  _id: new ObjectId("66bbc8f53a67736625ed2811"),
  models: {
    all: { active: true, model: { n: 5 } },
    available: { nCount: 5 },
    locked: { active: false },
    won: { nCount: 5 },
    wonBest: { nCount: 5 },
  },
  background: "n",
  levels: (
    [
      {
        _id: new ObjectId("66bbc90a3a67736625ed2870"),
        tutorial: true,
        condition: {
          type: "levels",
          count: 12,
          levelIds: level4Ids,
        },
        cells: [
          { position: [0, 1] },
          { position: [1, 0] },
          { position: [1, 1] },
          { position: [1, 2] },
          { position: [2, 1] },
          { position: [2, 2] },
        ],
        blocks: [
          { initialPosition: [0, 1], ...source },
          { initialPosition: [1, 1], ...rectangle(1, 1), active: false },
          { initialPosition: [1, 2], ...rectangle(1, 1), n: 4 },
          { initialPosition: [2, 1], ...sink },
          { initialPosition: [2, 2], ...rectangle(1, 1), active: false },
        ],
      },
      {
        _id: new ObjectId("66bbc8f53a67736625ed2813"),
        condition: {
          type: "levels",
          levelIds: [new ObjectId("66bbc90a3a67736625ed2870")],
        },
        cells: [
          { position: [0, 1] },
          { position: [1, 0] },
          { position: [1, 1] },
          { position: [2, 1] },
          { position: [2, 2] },
          { position: [3, 0] },
          { position: [3, 1] },
          { position: [4, 1] },
        ],
        blocks: [
          { initialPosition: [0, 1], ...source },
          { initialPosition: [1, 1], ...rectangle(1, 1) },
          { initialPosition: [2, 1], ...rectangle(1, 1), n: 3 },
          { initialPosition: [3, 0], ...rectangle(1, 1), active: false },
          { initialPosition: [3, 1], ...rectangle(1, 1) },
          { initialPosition: [4, 1], ...sink },
        ],
      },
      {
        _id: new ObjectId("66bbc90a3a67736625ed2871"),
        condition: {
          type: "levels",
          levelIds: [new ObjectId("66bbc90a3a67736625ed2870")],
        },
        cells: [
          { position: [0, 0] },
          { position: [0, 1] },
          { position: [0, 2] },
          { position: [1, 0] },
          { position: [1, 1] },
          { position: [1, 2] },
          { position: [2, 0] },
          { position: [2, 1] },
          { position: [2, 2] },
          { position: [3, 0] },
          { position: [3, 1] },
          { position: [3, 2] },
          { position: [4, 0] },
          { position: [4, 1] },
          { position: [4, 2] },
          { position: [5, 0] },
          { position: [5, 1] },
          { position: [5, 2] },
          { position: [6, 0] },
          { position: [6, 1] },
          { position: [6, 2] },
          { position: [7, 0] },
          { position: [7, 1] },
          { position: [7, 2] },
        ],
        blocks: [
          { initialPosition: [0, 1], ...source },
          { initialPosition: [0, 2], ...rectangle(3, 1), n: 3 },
          { initialPosition: [1, 0], ...rectangle(1, 1) },
          { initialPosition: [3, 0], ...rectangle(2, 2), active: false },
          { initialPosition: [5, 0], ...tRight, n: 3 },
          { initialPosition: [5, 2], ...rectangle(1, 1) },
          { initialPosition: [6, 2], ...rectangle(1, 1), active: false },
          { initialPosition: [7, 1], ...rectangle(1, 1), n: 3 },
          { initialPosition: [7, 2], ...sink },
        ],
      },
      {
        _id: new ObjectId("66bbc90a3a67736625ed2872"),
        condition: {
          type: "levels",
          levelIds: [new ObjectId("66bbc90a3a67736625ed2870")],
        },
        cells: [
          { position: [0, 0] },
          { position: [0, 1] },
          { position: [0, 2] },
          { position: [0, 3] },
          { position: [1, 0] },
          { position: [1, 1] },
          { position: [1, 2] },
          { position: [1, 3] },
          { position: [2, 0] },
          { position: [2, 1] },
          { position: [2, 2] },
          { position: [2, 3] },
          { position: [3, 0] },
          { position: [3, 1] },
          { position: [3, 2] },
          { position: [3, 3] },
          { position: [4, 0] },
          { position: [4, 1] },
          { position: [4, 2] },
          { position: [4, 3] },
          { position: [5, 0] },
          { position: [5, 1] },
          { position: [5, 2] },
          { position: [5, 3] },
          { position: [6, 0] },
          { position: [6, 1] },
          { position: [6, 2] },
          { position: [6, 3] },
          { position: [7, 0] },
          { position: [7, 1] },
          { position: [7, 2] },
          { position: [7, 3] },
        ],
        blocks: [
          { initialPosition: [0, 0], ...source },
          { initialPosition: [0, 1], ...rectangle(1, 1), active: false },
          { initialPosition: [2, 0], ...rectangle(1, 1), active: false },
          { initialPosition: [2, 2], ...rectangle(2, 2), n: 5 },
          { initialPosition: [3, 0], ...rectangle(2, 1), n: 2 },
          { initialPosition: [3, 1], ...rectangle(1, 1), active: false },
          { initialPosition: [4, 1], ...rectangle(1, 1), active: false },
          { initialPosition: [4, 2], ...rectangle(2, 2), n: 4 },
          { initialPosition: [5, 0], ...rectangle(1, 1), active: false },
          { initialPosition: [7, 2], ...sink },
        ],
      },
      {
        _id: new ObjectId("66bbc90a3a67736625ed2873"),
        condition: {
          type: "levels",
          levelIds: [new ObjectId("66bbc90a3a67736625ed2870")],
        },
        cells: [
          { position: [0, 0] },
          { position: [0, 1] },
          { position: [0, 2] },
          { position: [0, 3] },
          { position: [1, 0] },
          { position: [1, 1] },
          { position: [1, 2] },
          { position: [1, 3] },
          { position: [2, 0] },
          { position: [2, 1] },
          { position: [2, 2] },
          { position: [2, 3] },
          { position: [3, 0] },
          { position: [3, 1] },
          { position: [3, 2] },
          { position: [3, 3] },
          { position: [4, 0] },
          { position: [4, 1] },
          { position: [4, 2] },
          { position: [4, 3] },
          { position: [5, 0] },
          { position: [5, 1] },
          { position: [5, 2] },
          { position: [5, 3] },
          { position: [6, 0] },
          { position: [6, 1] },
          { position: [6, 2] },
          { position: [6, 3] },
          { position: [7, 0] },
          { position: [7, 1] },
          { position: [7, 2] },
          { position: [7, 3] },
        ],
        blocks: [
          { initialPosition: [0, 0], ...source },
          { initialPosition: [0, 3], ...rectangle(1, 1), n: 3 },
          { initialPosition: [1, 1], ...rectangle(2, 2), n: 7 },
          { initialPosition: [3, 0], ...rectangle(1, 1), active: false },
          { initialPosition: [3, 3], ...rectangle(1, 1), n: 2 },
          { initialPosition: [4, 0], ...rectangle(1, 1), active: false },
          { initialPosition: [4, 2], ...rectangle(1, 1), n: 3 },
          { initialPosition: [4, 3], ...rectangle(1, 1), active: false },
          { initialPosition: [5, 0], ...rectangle(2, 2), n: 4 },
          { initialPosition: [5, 2], ...rectangle(1, 1), active: false },
          { initialPosition: [7, 2], ...rectangle(1, 1), active: false },
          { initialPosition: [7, 3], ...sink },
        ],
      },
      {
        _id: new ObjectId("66bbc90a3a67736625ed2874"),
        condition: {
          type: "levels",
          levelIds: [new ObjectId("66bbc90a3a67736625ed2870")],
        },
        cells: [
          { position: [0, 0] },
          { position: [0, 1] },
          { position: [0, 2] },
          { position: [0, 3] },
          { position: [0, 4] },
          { position: [0, 5] },
          { position: [0, 6] },
          { position: [0, 7] },
          { position: [0, 8] },
          { position: [0, 9] },
          { position: [1, 0] },
          { position: [1, 1] },
          { position: [1, 2] },
          { position: [1, 3] },
          { position: [1, 4] },
          { position: [1, 5] },
          { position: [1, 6] },
          { position: [1, 7] },
          { position: [1, 8] },
          { position: [1, 9] },
          { position: [2, 0] },
          { position: [2, 1] },
          { position: [2, 4] },
          { position: [2, 5] },
          { position: [2, 8] },
          { position: [2, 9] },
          { position: [3, 0] },
          { position: [3, 1] },
          { position: [3, 4] },
          { position: [3, 5] },
          { position: [3, 8] },
          { position: [3, 9] },
          { position: [4, 0] },
          { position: [4, 1] },
          { position: [4, 2] },
          { position: [4, 3] },
          { position: [4, 4] },
          { position: [4, 5] },
          { position: [4, 6] },
          { position: [4, 7] },
          { position: [4, 8] },
          { position: [4, 9] },
          { position: [5, 0] },
          { position: [5, 1] },
          { position: [5, 2] },
          { position: [5, 3] },
          { position: [5, 4] },
          { position: [5, 5] },
          { position: [5, 6] },
          { position: [5, 7] },
          { position: [5, 8] },
          { position: [5, 9] },
          { position: [6, 0] },
          { position: [6, 1] },
          { position: [6, 4] },
          { position: [6, 5] },
          { position: [6, 8] },
          { position: [6, 9] },
          { position: [7, 0] },
          { position: [7, 1] },
          { position: [7, 4] },
          { position: [7, 5] },
          { position: [7, 8] },
          { position: [7, 9] },
          { position: [8, 0] },
          { position: [8, 1] },
          { position: [8, 2] },
          { position: [8, 3] },
          { position: [8, 4] },
          { position: [8, 5] },
          { position: [8, 6] },
          { position: [8, 7] },
          { position: [8, 8] },
          { position: [8, 9] },
          { position: [9, 0] },
          { position: [9, 1] },
          { position: [9, 2] },
          { position: [9, 3] },
          { position: [9, 4] },
          { position: [9, 5] },
          { position: [9, 6] },
          { position: [9, 7] },
          { position: [9, 8] },
          { position: [9, 9] },
        ],
        blocks: [
          { initialPosition: [0, 0], ...source },
          { initialPosition: [0, 2], ...rectangle(2, 2), n: 2 },
          { initialPosition: [0, 4], ...rectangle(2, 2), n: 2 },
          { initialPosition: [0, 6], ...rectangle(2, 2), n: 3 },
          { initialPosition: [0, 8], ...rectangle(2, 2), n: 4 },
          { initialPosition: [2, 0], ...rectangle(2, 2), active: false },
          { initialPosition: [2, 4], ...rectangle(2, 2), active: false },
          { initialPosition: [2, 8], ...rectangle(2, 2), n: 3 },
          { initialPosition: [4, 0], ...rectangle(2, 2), n: 3 },
          { initialPosition: [4, 8], ...rectangle(2, 2), active: false },
          { initialPosition: [8, 0], ...rectangle(2, 2), active: false },
          { initialPosition: [8, 2], ...rectangle(2, 2), active: false },
          { initialPosition: [8, 4], ...rectangle(2, 2), n: 3 },
          { initialPosition: [8, 6], ...rectangle(2, 2), active: false },
          { initialPosition: [9, 9], ...sink },
        ],
      },
      {
        _id: new ObjectId("66bbc90a3a67736625ed2875"),
        condition: {
          type: "levels",
          levelIds: [new ObjectId("66bbc90a3a67736625ed2870")],
        },
        cells: [
          { position: [0, 0] },
          { position: [0, 1] },
          { position: [0, 2] },
          { position: [0, 3] },
          { position: [0, 4] },
          { position: [1, 0] },
          { position: [1, 1] },
          { position: [1, 2] },
          { position: [1, 3] },
          { position: [1, 4] },
          { position: [2, 0] },
          { position: [2, 1] },
          { position: [2, 2] },
          { position: [2, 3] },
          { position: [2, 4] },
          { position: [3, 0] },
          { position: [3, 1] },
          { position: [3, 2] },
          { position: [3, 3] },
          { position: [3, 4] },
          { position: [4, 0] },
          { position: [4, 1] },
          { position: [4, 2] },
          { position: [4, 3] },
          { position: [4, 4] },
        ],
        blocks: [
          { initialPosition: [0, 0], ...shortLBottomRight, active: false },
          { initialPosition: [0, 0], ...source, mobile: true },
          { initialPosition: [0, 3], ...rectangle(1, 1), mobile: false, n: 2 },
          { initialPosition: [1, 1], ...plus, active: false },
          { initialPosition: [2, 0], ...rectangle(1, 1), mobile: false, n: 2 },
          { initialPosition: [3, 0], ...rectangle(1, 1), active: false },
          { initialPosition: [3, 3], ...rectangle(2, 1), active: false },
          { initialPosition: [4, 0], ...sink, mobile: true },
          { initialPosition: [4, 1], ...rectangle(1, 1), active: false },
          { initialPosition: [4, 4], ...sink, mobile: true },
        ],
      },
      {
        _id: new ObjectId("66bbc90a3a67736625ed2876"),
        condition: {
          type: "levels",
          levelIds: [new ObjectId("66bbc90a3a67736625ed2870")],
        },
        cells: [
          { position: [0, 0] },
          { position: [0, 1] },
          { position: [0, 2] },
          { position: [0, 3] },
          { position: [1, 0] },
          { position: [1, 1] },
          { position: [1, 2] },
          { position: [1, 3], active: true },
          { position: [2, 0] },
          { position: [2, 1] },
          { position: [2, 2] },
          { position: [2, 3], active: true },
          { position: [3, 0] },
          { position: [3, 1] },
          { position: [3, 2], active: true },
          { position: [3, 3], active: true },
          { position: [4, 0] },
          { position: [4, 1] },
          { position: [4, 2], active: true },
          { position: [4, 3] },
        ],
        blocks: [
          { initialPosition: [0, 0], ...source },
          { initialPosition: [0, 1], ...rectangle(1, 2), n: 3 },
          { initialPosition: [1, 1], ...rectangle(1, 1), active: false },
          { initialPosition: [1, 2], ...rectangle(2, 2), active: false },
          { initialPosition: [2, 0], ...rectangle(2, 2), n: 4 },
          { initialPosition: [3, 2], ...rectangle(1, 1), n: 2 },
          { initialPosition: [4, 1], ...rectangle(1, 2), active: false },
          { initialPosition: [4, 3], ...sink },
        ],
      },
      {
        _id: new ObjectId("66bbc90a3a67736625ed2877"),
        condition: {
          type: "levels",
          levelIds: [new ObjectId("66bbc90a3a67736625ed2870")],
        },
        cells: [
          { position: [0, 0] },
          { position: [0, 1] },
          { position: [0, 2] },
          { position: [0, 3] },
          { position: [0, 4] },
          { position: [0, 5] },
          { position: [0, 6] },
          { position: [0, 7] },
          { position: [0, 8] },
          { position: [1, 0] },
          { position: [1, 2] },
          { position: [1, 3] },
          { position: [1, 4] },
          { position: [1, 6] },
          { position: [1, 7] },
          { position: [1, 8] },
          { position: [2, 0] },
          { position: [2, 1] },
          { position: [2, 2] },
          { position: [2, 3] },
          { position: [2, 4] },
          { position: [2, 5] },
          { position: [2, 6] },
          { position: [2, 7] },
          { position: [2, 8] },
          { position: [3, 0] },
          { position: [3, 1] },
          { position: [3, 2] },
          { position: [3, 4] },
          { position: [3, 5] },
          { position: [3, 6] },
          { position: [3, 8] },
          { position: [4, 0] },
          { position: [4, 1] },
          { position: [4, 2] },
          { position: [4, 3] },
          { position: [4, 4] },
          { position: [4, 5] },
          { position: [4, 6] },
          { position: [4, 7] },
          { position: [4, 8] },
          { position: [5, 0] },
          { position: [5, 2] },
          { position: [5, 3] },
          { position: [5, 4] },
          { position: [5, 6] },
          { position: [5, 7] },
          { position: [5, 8] },
          { position: [6, 0] },
          { position: [6, 1] },
          { position: [6, 2] },
          { position: [6, 3] },
          { position: [6, 4] },
          { position: [6, 5] },
          { position: [6, 6] },
          { position: [6, 7] },
          { position: [6, 8] },
          { position: [7, 0] },
          { position: [7, 1] },
          { position: [7, 2] },
          { position: [7, 4] },
          { position: [7, 5] },
          { position: [7, 6] },
          { position: [8, 0] },
          { position: [8, 1] },
          { position: [8, 2] },
          { position: [8, 3] },
          { position: [8, 4] },
          { position: [8, 5] },
          { position: [8, 6] },
          { position: [8, 7] },
          { position: [8, 8] },
        ],
        blocks: [
          { initialPosition: [0, 0], ...source },
          { initialPosition: [0, 2], ...rectangle(1, 5), n: 2 },
          { initialPosition: [2, 0], ...rectangle(1, 3) },
          { initialPosition: [2, 5], ...rectangle(3, 1) },
          { initialPosition: [3, 2], ...rectangle(5, 1), n: 3 },
          { initialPosition: [4, 6], ...rectangle(1, 3) },
          { initialPosition: [5, 3], ...rectangle(2, 2), active: false },
          { initialPosition: [8, 0], ...rectangle(1, 1) },
          { initialPosition: [8, 1], ...rectangle(1, 5), n: 4 },
          { initialPosition: [8, 8], ...sink },
        ],
      },
      {
        _id: new ObjectId("66bbc90a3a67736625ed2878"),
        condition: {
          type: "levels",
          levelIds: [new ObjectId("66bbc90a3a67736625ed2870")],
        },
        cells: [
          { position: [0, 0] },
          { position: [0, 1] },
          { position: [0, 2] },
          { position: [0, 3] },
          { position: [0, 4] },
          { position: [0, 5] },
          { position: [0, 6] },
          { position: [0, 7] },
          { position: [0, 8] },
          { position: [1, 0] },
          { position: [1, 2] },
          { position: [1, 3] },
          { position: [1, 4] },
          { position: [1, 6] },
          { position: [1, 7] },
          { position: [1, 8] },
          { position: [2, 0] },
          { position: [2, 1] },
          { position: [2, 2] },
          { position: [2, 3] },
          { position: [2, 4] },
          { position: [2, 5] },
          { position: [2, 6] },
          { position: [2, 7] },
          { position: [2, 8] },
          { position: [3, 0] },
          { position: [3, 1] },
          { position: [3, 2] },
          { position: [3, 4] },
          { position: [3, 5] },
          { position: [3, 6] },
          { position: [3, 8] },
          { position: [4, 0] },
          { position: [4, 1] },
          { position: [4, 2] },
          { position: [4, 3] },
          { position: [4, 4] },
          { position: [4, 5] },
          { position: [4, 6] },
          { position: [4, 7] },
          { position: [4, 8] },
          { position: [5, 0] },
          { position: [5, 2] },
          { position: [5, 3] },
          { position: [5, 4] },
          { position: [5, 6] },
          { position: [5, 7] },
          { position: [5, 8] },
          { position: [6, 0] },
          { position: [6, 1] },
          { position: [6, 2] },
          { position: [6, 3] },
          { position: [6, 4] },
          { position: [6, 5] },
          { position: [6, 6] },
          { position: [6, 7] },
          { position: [6, 8] },
          { position: [7, 0] },
          { position: [7, 1] },
          { position: [7, 2] },
          { position: [7, 4] },
          { position: [7, 5] },
          { position: [7, 6] },
          { position: [7, 8] },
          { position: [8, 0] },
          { position: [8, 1] },
          { position: [8, 2] },
          { position: [8, 3] },
          { position: [8, 4] },
          { position: [8, 5] },
          { position: [8, 6] },
          { position: [8, 8] },
        ],
        blocks: [
          { initialPosition: [0, 0], ...source },
          { initialPosition: [0, 2], ...rectangle(1, 5), n: 2 },
          { initialPosition: [2, 0], ...rectangle(1, 3) },
          { initialPosition: [2, 5], ...rectangle(3, 1) },
          { initialPosition: [3, 2], ...rectangle(5, 1), n: 3 },
          { initialPosition: [4, 6], ...rectangle(1, 3) },
          { initialPosition: [5, 3], ...rectangle(2, 2), active: false },
          { initialPosition: [8, 0], ...rectangle(1, 1) },
          { initialPosition: [8, 1], ...rectangle(1, 5), n: 4 },
          { initialPosition: [8, 8], ...sink },
        ],
      },
      {
        _id: new ObjectId("66bbc90a3a67736625ed2879"),
        condition: {
          type: "levels",
          levelIds: [new ObjectId("66bbc90a3a67736625ed2870")],
        },
        cells: [
          { position: [0, 2] },
          { position: [0, 3] },
          { position: [0, 4] },
          { position: [0, 5] },
          { position: [1, 1] },
          { position: [1, 2] },
          { position: [1, 3] },
          { position: [1, 4] },
          { position: [1, 5] },
          { position: [2, 0] },
          { position: [2, 1] },
          { position: [2, 3] },
          { position: [2, 4] },
          { position: [2, 6] },
          { position: [2, 7] },
          { position: [3, 0] },
          { position: [3, 1] },
          { position: [3, 2] },
          { position: [3, 3] },
          { position: [3, 4] },
          { position: [3, 5] },
          { position: [3, 6] },
          { position: [3, 7] },
          { position: [4, 0] },
          { position: [4, 1] },
          { position: [4, 2] },
          { position: [4, 3] },
          { position: [4, 4] },
          { position: [4, 5] },
          { position: [4, 6] },
          { position: [4, 7] },
          { position: [5, 0] },
          { position: [5, 1] },
          { position: [5, 3] },
          { position: [5, 4] },
          { position: [5, 6] },
          { position: [5, 7] },
          { position: [6, 2] },
          { position: [6, 3] },
          { position: [6, 4] },
          { position: [6, 5] },
          { position: [6, 6] },
          { position: [7, 2] },
          { position: [7, 3] },
          { position: [7, 4] },
          { position: [7, 5] },
        ],
        blocks: [
          { initialPosition: [1, 1], ...source },
          { initialPosition: [1, 2], ...tDown, active: false },
          { initialPosition: [1, 5], ...rectangle(1, 1), n: 3 },
          { initialPosition: [2, 0], ...rectangle(1, 1), n: 3 },
          { initialPosition: [2, 1], ...rectangle(1, 1), n: 3 },
          { initialPosition: [2, 3], ...plus, n: 5 },
          { initialPosition: [2, 7], ...rectangle(1, 1), n: 2 },
          { initialPosition: [3, 0], ...shortLBottomLeft, active: false },
          { initialPosition: [3, 1], ...rectangle(1, 2), active: false },
          { initialPosition: [4, 5], ...rectangle(1, 3), active: false },
          { initialPosition: [5, 3], ...rectangle(3, 1), active: false },
          { initialPosition: [5, 6], ...rectangle(1, 1), n: 2 },
          { initialPosition: [6, 2], ...rectangle(1, 1), n: 2 },
          { initialPosition: [6, 6], ...sink },
        ],
      },
      {
        _id: new ObjectId("66bbc90a3a67736625ed287a"),
        condition: {
          type: "levels",
          levelIds: [new ObjectId("66bbc90a3a67736625ed2870")],
        },
        cells: [
          { position: [0, 0] },
          { position: [0, 2] },
          { position: [0, 3] },
          { position: [0, 4] },
          { position: [0, 5] },
          { position: [1, 0], active: true },
          { position: [1, 2] },
          { position: [1, 3] },
          { position: [1, 4] },
          { position: [1, 5] },
          { position: [2, 0], active: true },
          { position: [2, 2] },
          { position: [2, 3] },
          { position: [2, 4] },
          { position: [2, 5] },
          { position: [3, 0], active: true },
          { position: [3, 2] },
          { position: [3, 3] },
          { position: [3, 4] },
          { position: [3, 5] },
          { position: [4, 0] },
          { position: [4, 2] },
          { position: [4, 3] },
          { position: [4, 4] },
          { position: [4, 5] },
          { position: [5, 0], active: true },
          { position: [5, 1], active: true },
          { position: [5, 2], active: true },
          { position: [5, 3] },
          { position: [5, 4] },
          { position: [5, 5], active: true },
          { position: [5, 6], active: true },
          { position: [5, 7], active: true },
          { position: [6, 2] },
          { position: [6, 3] },
          { position: [6, 4] },
          { position: [6, 5] },
          { position: [6, 7] },
          { position: [7, 2] },
          { position: [7, 3] },
          { position: [7, 4] },
          { position: [7, 5] },
          { position: [7, 7], active: true },
          { position: [8, 2] },
          { position: [8, 3] },
          { position: [8, 4] },
          { position: [8, 5] },
          { position: [8, 7], active: true },
          { position: [9, 2] },
          { position: [9, 3] },
          { position: [9, 4] },
          { position: [9, 5] },
          { position: [9, 7], active: true },
          { position: [10, 2] },
          { position: [10, 3] },
          { position: [10, 4] },
          { position: [10, 5] },
          { position: [10, 7] },
        ],
        blocks: [
          { initialPosition: [0, 0], ...source },
          { initialPosition: [0, 3], ...rectangle(2, 1), active: false },
          { initialPosition: [0, 4], ...shortLTopRight, active: false },
          { initialPosition: [1, 4], ...shortLBottomLeft, active: false },
          { initialPosition: [3, 2], ...rectangle(2, 2), active: false },
          { initialPosition: [3, 4], ...tLeft, active: false },
          { initialPosition: [5, 2], ...longLTopLeft, active: false },
          { initialPosition: [6, 3], ...lBottomRight, active: false },
          { initialPosition: [8, 2], ...rectangle(1, 1), n: 1 },
          { initialPosition: [8, 3], ...rectangle(1, 1), n: 1 },
          { initialPosition: [8, 4], ...rectangle(1, 1), n: 1 },
          { initialPosition: [8, 5], ...rectangle(1, 1), n: 1 },
          { initialPosition: [9, 2], ...rectangle(1, 1), n: 1 },
          { initialPosition: [9, 3], ...rectangle(1, 1), n: 1 },
          { initialPosition: [9, 4], ...rectangle(1, 1), n: 1 },
          { initialPosition: [9, 5], ...rectangle(1, 1), n: 1 },
          { initialPosition: [10, 2], ...rectangle(1, 1), n: 2 },
          { initialPosition: [10, 3], ...rectangle(1, 1), n: 3 },
          { initialPosition: [10, 4], ...rectangle(1, 1), n: 3 },
          { initialPosition: [10, 5], ...rectangle(1, 1), n: 2 },
          { initialPosition: [10, 7], ...sink },
        ],
      },
      {
        _id: new ObjectId("66bbc90a3a67736625ed287e"),
        condition: {
          type: "levels",
          levelIds: [new ObjectId("66bbc90a3a67736625ed2870")],
        },
        cells: [
          { position: [0, 0] },
          { position: [0, 1] },
          { position: [0, 2] },
          { position: [0, 3] },
          { position: [1, 0] },
          { position: [1, 1] },
          { position: [1, 2] },
          { position: [1, 3] },
          { position: [2, 0] },
          { position: [2, 1] },
          { position: [2, 2] },
          { position: [2, 3] },
          { position: [3, 0] },
          { position: [3, 1] },
          { position: [3, 2] },
          { position: [3, 3] },
        ],
        blocks: [
          { initialPosition: [0, 0], ...source },
          { initialPosition: [0, 1], ...rectangle(1, 1), active: false },
          { initialPosition: [0, 2], ...rectangle(1, 1), active: false },
          { initialPosition: [0, 3], ...rectangle(1, 1), n: 4 },
          { initialPosition: [1, 0], ...rectangle(1, 1), active: false },
          { initialPosition: [1, 1], ...rectangle(1, 1), active: false },
          { initialPosition: [1, 3], ...rectangle(1, 1), n: 2 },
          { initialPosition: [2, 0], ...rectangle(1, 1), active: false },
          { initialPosition: [2, 1], ...rectangle(1, 1), n: 3 },
          { initialPosition: [2, 2], ...rectangle(1, 1), n: 3 },
          { initialPosition: [2, 3], ...rectangle(1, 1), n: 4 },
          { initialPosition: [3, 0], ...rectangle(1, 1), active: false },
          { initialPosition: [3, 1], ...rectangle(1, 1), active: false },
          { initialPosition: [3, 2], ...rectangle(1, 1), active: false },
          { initialPosition: [3, 3], ...sink },
        ],
      },
      {
        _id: new ObjectId("66bbc90a3a67736625ed287b"),
        tutorial: true,
        condition: {
          type: "levels",
          levelIds: [new ObjectId("66bbc90a3a67736625ed2870")],
        },
        cells: [
          { position: [0, 1] },
          { position: [1, 1] },
          { position: [1, 2] },
          { position: [2, 1] },
          { position: [2, 2] },
        ],
        blocks: [
          { initialPosition: [0, 1], ...source, n: 1 },
          { initialPosition: [1, 2], ...rectangle(1, 1) },
          { initialPosition: [2, 2], ...rectangle(1, 1), active: false },
          { initialPosition: [2, 1], ...sink, n: 1 },
        ],
      },
      {
        _id: new ObjectId("66bbc90a3a67736625ed287d"),
        condition: {
          type: "levels",
          levelIds: [new ObjectId("66bbc90a3a67736625ed287b")],
        },
        cells: [
          { position: [0, 0] },
          { position: [0, 1] },
          { position: [0, 2] },
          { position: [0, 3] },
          { position: [0, 4] },
          { position: [0, 5] },
          { position: [0, 6] },
          { position: [1, 0] },
          { position: [1, 1] },
          { position: [1, 2] },
          { position: [1, 3] },
          { position: [1, 4] },
          { position: [1, 5] },
          { position: [1, 6] },
          { position: [2, 0] },
          { position: [2, 1] },
          { position: [2, 2] },
          { position: [2, 3] },
          { position: [2, 4] },
          { position: [2, 5] },
          { position: [2, 6] },
          { position: [3, 0] },
          { position: [3, 1] },
          { position: [3, 2] },
          { position: [3, 3] },
          { position: [3, 4] },
          { position: [3, 5] },
          { position: [3, 6] },
          { position: [4, 0] },
          { position: [4, 1] },
          { position: [4, 2] },
          { position: [4, 3] },
          { position: [4, 4] },
          { position: [4, 5] },
          { position: [4, 6] },
          { position: [5, 0] },
          { position: [5, 1] },
          { position: [5, 2] },
          { position: [5, 3] },
          { position: [5, 4] },
          { position: [5, 5] },
          { position: [5, 6] },
          { position: [6, 0] },
          { position: [6, 1] },
          { position: [6, 2] },
          { position: [6, 3] },
          { position: [6, 4] },
          { position: [6, 5] },
          { position: [6, 6] },
          { position: [7, 0] },
          { position: [7, 1] },
          { position: [7, 2] },
          { position: [7, 3] },
          { position: [7, 4] },
          { position: [7, 5] },
          { position: [7, 6] },
          { position: [8, 0] },
          { position: [8, 1] },
          { position: [8, 2] },
          { position: [8, 3] },
          { position: [8, 4] },
          { position: [8, 5] },
          { position: [8, 6] },
        ],
        blocks: [
          { initialPosition: [0, 0], ...source },
          { initialPosition: [0, 1], ...rectangle(1, 1), mobile: false },
          { initialPosition: [0, 2], ...rectangle(1, 1), mobile: false },
          { initialPosition: [0, 3], ...rectangle(1, 1), mobile: false },
          { initialPosition: [0, 4], ...rectangle(1, 1), mobile: false },
          { initialPosition: [0, 5], ...rectangle(1, 1), mobile: false, n: 3 },
          { initialPosition: [0, 6], ...sink },
          { initialPosition: [1, 1], ...rectangle(1, 1), mobile: false },
          { initialPosition: [1, 4], ...rectangle(1, 1), mobile: false },
          { initialPosition: [1, 5], ...rectangle(1, 1), n: 2 },
          { initialPosition: [1, 6], ...rectangle(1, 1), mobile: false, n: 2 },
          { initialPosition: [2, 0], ...rectangle(1, 1), n: 2 },
          { initialPosition: [2, 1], ...rectangle(1, 1), n: 4 },
          { initialPosition: [2, 2], ...rectangle(1, 1), n: 2 },
          { initialPosition: [3, 1], ...rectangle(1, 1), mobile: false },
          { initialPosition: [3, 4], ...rectangle(1, 1), mobile: false, n: 4 },
          { initialPosition: [3, 6], ...rectangle(1, 1), mobile: false },
          { initialPosition: [4, 1], ...rectangle(1, 1), mobile: false },
          { initialPosition: [5, 0], ...rectangle(1, 1), mobile: false },
          { initialPosition: [5, 1], ...rectangle(1, 1), mobile: false },
          { initialPosition: [5, 4], ...rectangle(1, 1), mobile: false },
          { initialPosition: [5, 5], ...rectangle(1, 1), mobile: false },
          { initialPosition: [6, 0], ...rectangle(1, 1), mobile: false },
          { initialPosition: [6, 1], ...rectangle(1, 1), n: 3 },
          { initialPosition: [7, 1], ...rectangle(1, 1), n: 3 },
          { initialPosition: [7, 4], ...rectangle(1, 1), mobile: false },
          { initialPosition: [7, 5], ...rectangle(1, 1), n: 2 },
          { initialPosition: [8, 0], ...sink, n: 1 },
          { initialPosition: [8, 1], ...rectangle(1, 1), n: 3 },
          { initialPosition: [8, 2], ...rectangle(1, 1), mobile: false },
          { initialPosition: [8, 5], ...rectangle(1, 1), mobile: false },
          { initialPosition: [8, 6], ...sink },
          ...(
            [
              { initialPosition: [3, 2], ...rectangle(1, 1) },
              { initialPosition: [3, 5], ...rectangle(1, 1) },
              { initialPosition: [5, 2], ...rectangle(1, 1) },
              { initialPosition: [6, 4], ...rectangle(1, 1) },
              { initialPosition: [7, 2], ...rectangle(1, 1) },
              { initialPosition: [7, 6], ...rectangle(1, 1) },
              { initialPosition: [8, 4], ...rectangle(1, 1) },
            ] satisfies BlockData[]
          ).map((block) => ({ ...block, mobile: false, active: false })),
        ],
      },
      {
        _id: new ObjectId("66bbc90a3a67736625ed287c"),
        condition: {
          type: "levels",
          levelIds: [new ObjectId("66bbc90a3a67736625ed287b")],
        },
        cells: [
          { position: [0, 0] },
          { position: [0, 1] },
          { position: [0, 2] },
          { position: [0, 4] },
          { position: [0, 5] },
          { position: [0, 6] },
          { position: [1, 0] },
          { position: [1, 1] },
          { position: [1, 2] },
          { position: [1, 3] },
          { position: [1, 4] },
          { position: [1, 5] },
          { position: [1, 6] },
          { position: [2, 0] },
          { position: [2, 1] },
          { position: [2, 2] },
          { position: [2, 3] },
          { position: [2, 4] },
          { position: [2, 5] },
          { position: [2, 6] },
          { position: [3, 1] },
          { position: [3, 2] },
          { position: [3, 3] },
          { position: [3, 4] },
          { position: [3, 5] },
          { position: [4, 1] },
          { position: [4, 2] },
          { position: [4, 3] },
          { position: [4, 4] },
          { position: [4, 5] },
          { position: [5, 1] },
          { position: [5, 2] },
          { position: [5, 3] },
          { position: [5, 4] },
          { position: [5, 5] },
          { position: [6, 1] },
          { position: [6, 2] },
          { position: [6, 3] },
          { position: [6, 4] },
          { position: [6, 5] },
          { position: [7, 1] },
          { position: [7, 2] },
          { position: [7, 3] },
          { position: [7, 4] },
          { position: [7, 5] },
          { position: [8, 0] },
          { position: [8, 1] },
          { position: [8, 2] },
          { position: [8, 3] },
          { position: [8, 4] },
          { position: [8, 5] },
          { position: [8, 6] },
          { position: [9, 0] },
          { position: [9, 1] },
          { position: [9, 2] },
          { position: [9, 3] },
          { position: [9, 4] },
          { position: [9, 5] },
          { position: [9, 6] },
          { position: [10, 0] },
          { position: [10, 1] },
          { position: [10, 2] },
          { position: [10, 4] },
          { position: [10, 5] },
          { position: [10, 6] },
        ],
        blocks: [
          { initialPosition: [0, 5], ...shortLBottomLeft, active: false },
          { initialPosition: [0, 6], ...rectangle(1, 1), active: false },
          { initialPosition: [1, 1], ...lBottomLeft, active: false },
          { initialPosition: [1, 2], ...shortLBottomLeft, active: false },
          { initialPosition: [1, 4], ...shortLBottomLeft, active: false },
          { initialPosition: [10, 1], ...rectangle(1, 2), n: 6 },
          { initialPosition: [10, 6], ...rectangle(1, 1), active: false },
          { initialPosition: [3, 3], ...source, n: 1 },
          { initialPosition: [3, 4], ...shortLBottomLeft, active: false },
          { initialPosition: [4, 3], ...rectangle(1, 1), mobile: false, n: 3 },
          { initialPosition: [6, 3], ...rectangle(1, 1), mobile: false, n: 3 },
          { initialPosition: [6, 4], ...shortLTopLeft, active: false },
          { initialPosition: [7, 1], ...reverseLTopLeft, active: false },
          { initialPosition: [7, 3], ...sink, n: 1 },
          { initialPosition: [8, 2], ...shortLTopLeft, active: false },
          { initialPosition: [8, 4], ...shortLTopLeft, active: false },
          { initialPosition: [9, 5], ...shortLTopLeft, active: false },
        ],
      },
    ] satisfies AreaData["levels"]
  ).map(({ ...area }, index) => ({
    ...area,
    models: {
      all: { model: { n: index + 1 } },
      available: { nCount: index + 1 },
      won: { nCount: index + 1 },
      wonBest: { nCount: index + 1 },
    },
  })),
} satisfies AreaData);
