import { useScopedLocalStorage } from "./use-local-storage";

export const useLastLevelOpened = () =>
  useScopedLocalStorage(
    ({ lastLevelOpened } = {}) => lastLevelOpened,
    (scoped, lastLevelOpened) => ({
      ...scoped,
      lastLevelOpened,
    })
  );
