import { mapValues, omit, pickBy } from "lodash/fp";
import type { ObjectId } from "mongodb/lib/bson";
import { useCallback } from "react";

import type { Level } from "@sunblocks/game";
import { unflow } from "@sunblocks/utils";

import { useScopedLocalStorage } from "./use-local-storage";
import { useScopedSessionStorage } from "./use-session-storage";

export const usePersonalBestScores = (levelById: { [key: string]: Level }) => {
  const [previousBestScores, setPreviousBestScores] = useScopedSessionStorage(
    useCallback(
      (stored) =>
        unflow(
          stored?.levels,
          pickBy(
            (value, id) =>
              "previousBestScore" in (value ?? {}) &&
              value?.rev === levelById[id]?._rev
          ),
          (obj) => obj as NonNullable<NonNullable<typeof stored>["levels"]>,
          mapValues((value) => value?.previousBestScore)
        ),
      [levelById]
    ),
    useCallback(
      (stored, previousBestScores) => ({
        ...stored,
        levels: Object.fromEntries(
          Object.keys({ ...stored?.levels, ...previousBestScores }).map(
            (levelId) => [
              levelId,
              {
                ...(stored?.levels?.[levelId]?.rev === levelById[levelId]!._rev
                  ? omit(["previousBestScore"], stored?.levels?.[levelId])
                  : {}),
                ...(!(levelId in previousBestScores)
                  ? {}
                  : { previousBestScore: previousBestScores[levelId] }),
                rev: levelById[levelId]!._rev,
              },
            ]
          )
        ),
      }),
      [levelById]
    )
  );

  const [personalBestScores, setPersonalBestScores] = useScopedLocalStorage(
    useCallback(
      (stored) =>
        unflow(
          stored?.levels,
          pickBy(
            ({ bestScore, rev }, id) => bestScore && rev === levelById[id]?._rev
          ),
          (obj) => obj as NonNullable<NonNullable<typeof stored>["levels"]>,
          mapValues(({ bestScore }) => bestScore!)
        ),
      [levelById]
    ),
    useCallback(
      (stored, personalBestScores) => ({
        ...stored,
        levels: {
          ...stored?.levels,
          ...Object.fromEntries(
            Object.keys(personalBestScores).map((levelId) => [
              levelId,
              {
                ...(stored?.levels?.[levelId]?.rev === levelById[levelId]!._rev
                  ? stored?.levels?.[levelId]
                  : {}),
                bestScore: personalBestScores[levelId],
                rev: levelById[levelId]!._rev,
              },
            ])
          ),
        },
      }),
      [levelById]
    )
  );

  return [
    {
      personalBestScores,
      previousBestScores,
    },
    {
      removePreviousBestScore: useCallback(
        (levelId: ObjectId) => setPreviousBestScores(omit([`${levelId}`])),
        [setPreviousBestScores]
      ),
      setPersonalBestScore: useCallback(
        (levelId: ObjectId, score: number) => {
          const currentPersonalBestScore = personalBestScores[`${levelId}`];

          setPersonalBestScores((personalBestScores) => ({
            ...personalBestScores,
            [`${levelId}`]: score,
          }));

          setPreviousBestScores((previousBestScores) => ({
            ...previousBestScores,
            [`${levelId}`]: currentPersonalBestScore,
          }));
        },
        [personalBestScores, setPersonalBestScores, setPreviousBestScores]
      ),
    },
  ] satisfies [any, any];
};
