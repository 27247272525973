import { ObjectId } from "mongodb/lib/bson";

import type { AreaData, BlockData } from "@sunblocks/game";
import { zArea } from "@sunblocks/game";

import { area8 } from "./area-8";
import {
  lTopRight,
  longLBottomRight,
  longLTopLeft,
  longTRight,
  longTUp,
  plus,
  rectangle,
  reverseLBottomLeft,
  reverseLTopLeft,
  s90,
  shortLBottomLeft,
  shortLBottomRight,
  shortLTopRight,
  sink,
  source,
  tDown,
  tLeft,
  tRight,
  tUp,
  z,
  z90,
} from "./shapes";

const level8Ids = area8.levels.map(({ _id }) => _id);

export const area9 = zArea.parse({
  _id: new ObjectId("66bbcad53a67736625ed2d24"),
  models: {
    all: { night: true, fill: "sun" },
    won: { fill: "unfilled" },
    wonBest: { fill: "unfilled", model: { sink: "unfilled" } },
  },
  background: "night",
  levels: [
    {
      _id: new ObjectId("66bbcaed3a67736625ed2d3f"),
      tutorial: true,
      condition: {
        type: "levels",
        count: 12,
        levelIds: level8Ids,
      },
      cells: [
        { position: [0, 1] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [2, 1] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source },
        { initialPosition: [1, 1], ...rectangle(1, 1) },
        { initialPosition: [2, 1], ...rectangle(1, 1), sink: "unfilled" },
      ],
    },
    {
      _id: new ObjectId("66bbcaed3a67736625ed2d40"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbcaed3a67736625ed2d3f")],
      },
      cells: [
        { position: [0, 1] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [4, 1] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source },
        { initialPosition: [1, 0], ...rectangle(1, 1) },
        { initialPosition: [1, 1], ...rectangle(1, 1) },
        { initialPosition: [1, 2], ...rectangle(1, 1) },
        { initialPosition: [2, 0], ...rectangle(1, 1) },
        { initialPosition: [2, 1], ...rectangle(1, 1) },
        { initialPosition: [2, 2], ...rectangle(1, 1), sink: "unfilled" },
        { initialPosition: [4, 1], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbcaed3a67736625ed2d41"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbcaed3a67736625ed2d3f")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source },
        { initialPosition: [0, 2], ...rectangle(3, 1) },
        { initialPosition: [1, 0], ...source, source: "water", mobile: true },
        { initialPosition: [3, 0], ...tRight },
        { initialPosition: [5, 1], ...sink, sink: "water", mobile: true },
        { initialPosition: [5, 2], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [7, 1], ...rectangle(1, 1) },
        { initialPosition: [7, 2], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbcaed3a67736625ed2d42"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbcaed3a67736625ed2d3f")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [0, 2], ...rectangle(1, 1) },
        { initialPosition: [0, 3], ...sink, sink: "unfilled" },
        { initialPosition: [1, 0], ...rectangle(1, 1) },
        { initialPosition: [1, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 2], ...rectangle(1, 1) },
        { initialPosition: [1, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 0], ...sink, sink: "unfilled" },
        { initialPosition: [2, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 3], ...rectangle(1, 1) },
        { initialPosition: [3, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 1], ...rectangle(1, 1) },
        { initialPosition: [3, 3], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbcaed3a67736625ed2d43"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbcaed3a67736625ed2d3f")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 2], ...rectangle(3, 1) },
        { initialPosition: [0, 3], ...rectangle(1, 2) },
        { initialPosition: [1, 0], ...rectangle(1, 2) },
        { initialPosition: [1, 3], ...rectangle(1, 2) },
        { initialPosition: [2, 3], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [2, 4], ...rectangle(2, 1) },
        { initialPosition: [3, 0], ...rectangle(2, 1) },
        { initialPosition: [3, 1], ...rectangle(2, 1) },
        { initialPosition: [3, 2], ...rectangle(1, 2) },
        { initialPosition: [4, 2], ...rectangle(1, 2) },
        { initialPosition: [4, 4], ...source },
      ],
    },
    {
      _id: new ObjectId("66bbcaed3a67736625ed2d44"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbcaed3a67736625ed2d3f")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...reverseLTopLeft },
        { initialPosition: [0, 3], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [0, 4], ...lTopRight },
        { initialPosition: [1, 2], ...tDown },
        { initialPosition: [2, 0], ...s90 },
        { initialPosition: [2, 2], ...rectangle(2, 1) },
        { initialPosition: [2, 4], ...rectangle(2, 1) },
        { initialPosition: [2, 5], ...z90 },
        { initialPosition: [3, 3], ...source },
        { initialPosition: [4, 0], ...rectangle(2, 1) },
        { initialPosition: [4, 2], ...rectangle(3, 1) },
        { initialPosition: [4, 3], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [4, 4], ...rectangle(3, 1) },
        { initialPosition: [4, 6], ...rectangle(2, 1) },
        { initialPosition: [5, 2], ...longTUp },
        { initialPosition: [6, 0], ...rectangle(2, 1) },
        { initialPosition: [6, 1], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [6, 5], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [6, 6], ...rectangle(2, 1) },
      ],
    },
    {
      _id: new ObjectId("66bbcaed3a67736625ed2d45"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbcaed3a67736625ed2d3f")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 1], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [0, 3], ...rectangle(1, 2) },
        { initialPosition: [0, 6], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [1, 2], ...tDown },
        { initialPosition: [2, 1], ...rectangle(2, 2) },
        { initialPosition: [2, 4], ...rectangle(2, 2) },
        { initialPosition: [3, 0], ...tRight },
        { initialPosition: [3, 2], ...plus },
        { initialPosition: [3, 5], ...tLeft },
        { initialPosition: [5, 1], ...rectangle(2, 2) },
        { initialPosition: [5, 4], ...rectangle(2, 2) },
        { initialPosition: [6, 2], ...tUp },
        { initialPosition: [8, 3], ...rectangle(1, 3) },
        { initialPosition: [8, 6], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbcaed3a67736625ed2d46"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbcaed3a67736625ed2d3f")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...rectangle(1, 3) },
        { initialPosition: [0, 5], ...shortLTopRight },
        { initialPosition: [1, 0], ...rectangle(3, 1) },
        { initialPosition: [1, 2], ...source, mobile: true },
        { initialPosition: [1, 3], ...z },
        { initialPosition: [2, 1], ...tDown },
        { initialPosition: [2, 5], ...tLeft },
        { initialPosition: [3, 1], ...s90 },
        { initialPosition: [3, 3], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [3, 3], ...tLeft },
        { initialPosition: [4, 5], ...sink, mobile: true },
        { initialPosition: [5, 0], ...shortLBottomLeft },
        { initialPosition: [5, 2], ...tUp },
        { initialPosition: [6, 6], ...source, source: "water" },
      ],
    },
    {
      _id: new ObjectId("66bbcaed3a67736625ed2d47"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbcaed3a67736625ed2d3f")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source, mobile: true },
        { initialPosition: [0, 1], ...longTRight },
        { initialPosition: [0, 3], ...rectangle(1, 2) },
        { initialPosition: [1, 0], ...source, source: "water", mobile: true },
        { initialPosition: [1, 5], ...rectangle(2, 1) },
        { initialPosition: [2, 1], ...plus },
        { initialPosition: [2, 3], ...sink, mobile: true },
        { initialPosition: [2, 4], ...rectangle(5, 1) },
        { initialPosition: [3, 0], ...source, source: "water", mobile: true },
        { initialPosition: [3, 5], ...rectangle(2, 1) },
        { initialPosition: [4, 0], ...source, mobile: true },
        { initialPosition: [4, 1], ...longTRight },
        { initialPosition: [4, 3], ...sink, sink: "water", mobile: true },
        { initialPosition: [5, 5], ...sink, sink: "water", mobile: true },
        { initialPosition: [6, 5], ...sink, sink: "unfilled", mobile: true },
      ],
    },
    {
      _id: new ObjectId("66bbcaed3a67736625ed2d48"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbcaed3a67736625ed2d3f")],
      },
      cells: [
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [1, 7] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [2, 7] },
        { position: [2, 8] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [3, 7] },
        { position: [3, 8] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [4, 7] },
        { position: [4, 8] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [5, 7] },
        { position: [5, 8] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [6, 7] },
        { position: [6, 8] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [7, 7] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...longLBottomRight },
        { initialPosition: [0, 4], ...sink, sink: "water", mobile: true },
        { initialPosition: [0, 5], ...rectangle(2, 1) },
        { initialPosition: [1, 1], ...source, mobile: true },
        { initialPosition: [1, 3], ...tUp },
        { initialPosition: [1, 6], ...shortLBottomLeft },
        { initialPosition: [1, 7], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [3, 1], ...rectangle(3, 1) },
        { initialPosition: [3, 3], ...rectangle(3, 3) },
        { initialPosition: [3, 6], ...rectangle(2, 1) },
        { initialPosition: [3, 7], ...rectangle(1, 2) },
        { initialPosition: [4, 8], ...rectangle(2, 1) },
        { initialPosition: [5, 2], ...tRight },
        { initialPosition: [5, 6], ...rectangle(1, 2) },
        { initialPosition: [6, 0], ...rectangle(1, 2) },
        { initialPosition: [6, 6], ...longLTopLeft },
        { initialPosition: [7, 1], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [7, 3], ...rectangle(2, 1) },
        { initialPosition: [7, 4], ...rectangle(1, 2) },
        { initialPosition: [7, 7], ...source, source: "water", mobile: true },
        { initialPosition: [8, 4], ...sink, mobile: true },
      ],
    },
    {
      _id: new ObjectId("66bbcaed3a67736625ed2d49"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbcaed3a67736625ed2d3f")],
      },
      cells: [
        { position: [0, 0], active: true },
        { position: [0, 1], active: true },
        { position: [0, 2], active: true },
        { position: [0, 3], active: true },
        { position: [1, 0], active: true },
        { position: [1, 1], active: true },
        { position: [1, 2], active: true },
        { position: [1, 3], active: true },
        { position: [2, 0], active: true },
        { position: [2, 1], active: true },
        { position: [2, 2], active: true },
        { position: [2, 3], active: true },
        { position: [3, 0], active: true },
        { position: [3, 1], active: true },
        { position: [3, 2], active: true },
        { position: [3, 3], active: true },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source, mobile: true },
        { initialPosition: [1, 0], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [5, 0], ...sink, mobile: true },
        { initialPosition: [8, 0], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [0, 1], ...rectangle(1, 3) },
        { initialPosition: [1, 1], ...shortLBottomLeft },
        { initialPosition: [2, 2], ...shortLBottomRight },
        { initialPosition: [3, 0], ...reverseLBottomLeft },
        { initialPosition: [5, 1], ...rectangle(2, 1) },
        { initialPosition: [5, 3], ...rectangle(3, 1) },
        { initialPosition: [6, 0], ...rectangle(2, 1) },
        { initialPosition: [6, 2], ...rectangle(3, 1) },
        { initialPosition: [7, 1], ...rectangle(2, 1) },
      ],
    },
    {
      _id: new ObjectId("66bbcaed3a67736625ed2d4a"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbcaed3a67736625ed2d3f")],
      },
      cells: [
        { position: [0, 0], active: true },
        { position: [0, 1], active: true },
        { position: [0, 2], active: true },
        { position: [0, 3], active: true },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [2, 0], active: true },
        { position: [2, 1], active: true },
        { position: [2, 2], active: true },
        { position: [2, 3], active: true },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [4, 0], active: true },
        { position: [4, 1], active: true },
        { position: [4, 2], active: true },
        { position: [4, 3], active: true },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [6, 0], active: true },
        { position: [6, 1], active: true },
        { position: [6, 2], active: true },
        { position: [6, 3], active: true },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [8, 0], active: true },
        { position: [8, 1], active: true },
        { position: [8, 2], active: true },
        { position: [8, 3], active: true },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 1], ...rectangle(1, 1) },
        { initialPosition: [0, 2], ...rectangle(1, 1) },
        { initialPosition: [0, 3], ...rectangle(1, 1) },
        { initialPosition: [1, 0], ...rectangle(3, 1) },
        { initialPosition: [1, 1], ...rectangle(3, 1) },
        { initialPosition: [1, 2], ...rectangle(3, 1) },
        { initialPosition: [1, 3], ...rectangle(3, 1) },
        { initialPosition: [4, 0], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [4, 1], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [4, 2], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [6, 0], ...rectangle(1, 3) },
        { initialPosition: [7, 0], ...rectangle(1, 3) },
        { initialPosition: [8, 0], ...rectangle(1, 3) },
        { initialPosition: [8, 3], ...source },
      ],
    },
    {
      _id: new ObjectId("66bbcaed3a67736625ed2d4b"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbcaed3a67736625ed2d3f")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 4], ...sink, sink: "unfilled" },
        { initialPosition: [0, 6], ...source, source: "water" },
        { initialPosition: [4, 3], ...sink, sink: "unfilled" },
        { initialPosition: [5, 3], ...rectangle(1, 1) },
        { initialPosition: [6, 2], ...rectangle(1, 1) },
        { initialPosition: [6, 3], ...rectangle(1, 1) },
        { initialPosition: [6, 4], ...rectangle(1, 1) },
        { initialPosition: [7, 2], ...rectangle(1, 1) },
        { initialPosition: [7, 4], ...rectangle(1, 1) },
        { initialPosition: [7, 5], ...rectangle(1, 1) },
        { initialPosition: [8, 0], ...sink },
        { initialPosition: [8, 1], ...rectangle(1, 1) },
        { initialPosition: [8, 2], ...rectangle(1, 1) },
        { initialPosition: [8, 5], ...rectangle(1, 1) },
        { initialPosition: [8, 6], ...sink, sink: "water" },
        ...(
          [
            { initialPosition: [0, 1], ...rectangle(1, 1) },
            { initialPosition: [1, 1], ...rectangle(1, 1) },
            { initialPosition: [1, 2], ...rectangle(1, 1) },
            { initialPosition: [1, 4], ...rectangle(1, 1) },
            { initialPosition: [2, 4], ...rectangle(1, 1) },
            { initialPosition: [2, 5], ...rectangle(1, 1) },
            { initialPosition: [3, 0], ...rectangle(1, 1) },
            { initialPosition: [3, 2], ...rectangle(1, 1) },
            { initialPosition: [3, 5], ...rectangle(1, 1) },
            { initialPosition: [4, 2], ...rectangle(1, 1) },
            { initialPosition: [4, 4], ...rectangle(1, 1) },
            { initialPosition: [4, 5], ...rectangle(1, 1) },
            { initialPosition: [5, 1], ...rectangle(1, 1) },
            { initialPosition: [5, 2], ...rectangle(1, 1) },
            { initialPosition: [6, 5], ...rectangle(1, 1) },
            { initialPosition: [6, 6], ...rectangle(1, 1) },
            { initialPosition: [7, 0], ...rectangle(1, 1) },
            { initialPosition: [7, 1], ...rectangle(1, 1) },
            { initialPosition: [7, 3], ...rectangle(1, 1) },
            { initialPosition: [7, 6], ...rectangle(1, 1) },
            { initialPosition: [8, 3], ...rectangle(1, 1) },
            { initialPosition: [8, 4], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, mobile: false, weak: true })),
      ],
    },
    {
      _id: new ObjectId("66bbcaed3a67736625ed2d4c"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbcaed3a67736625ed2d3f")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 0] },
        { position: [2, 1], active: true },
        { position: [2, 2] },
        { position: [2, 3], active: true },
        { position: [2, 4], active: true },
        { position: [2, 5], active: true },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1], active: true },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1], active: true },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3], active: true },
        { position: [5, 4], active: true },
        { position: [5, 5], active: true },
        { position: [5, 6] },
        { position: [6, 0], active: true },
        { position: [6, 1], active: true },
        { position: [6, 2], active: true },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3], active: true },
        { position: [7, 4], active: true },
        { position: [7, 5], active: true },
        { position: [7, 6] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
      ],
      blocks: [
        { initialPosition: [0, 3], ...source },
        { initialPosition: [0, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [0, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [1, 0], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [1, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [1, 5], ...rectangle(1, 1), mobile: false, n: 3 },
        { initialPosition: [1, 6], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [2, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 3], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [2, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 6], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [3, 5], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [3, 6], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [4, 0], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [4, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 3], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [4, 5], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [4, 6], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [5, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 0], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 0], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 6], ...rectangle(1, 1), mobile: false, n: 3 },
        { initialPosition: [8, 0], ...rectangle(1, 1), mobile: false },
        { initialPosition: [8, 1], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [8, 3], ...sink },
        { initialPosition: [8, 4], ...rectangle(1, 1) },
        { initialPosition: [8, 5], ...rectangle(1, 1) },
        { initialPosition: [8, 6], ...rectangle(1, 1) },
      ],
    },
    {
      _id: new ObjectId("66bbcaed3a67736625ed2d4d"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbcaed3a67736625ed2d3f")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [7, 0], active: true },
        { position: [7, 1], active: true },
        { position: [7, 2], active: true },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 1], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [0, 6], ...source, source: "water" },
        { initialPosition: [1, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [1, 3], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [1, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [1, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [1, 6], ...rectangle(1, 1), mobile: false, n: 3 },
        { initialPosition: [2, 4], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [3, 0], ...rectangle(1, 1), mobile: false },
        { initialPosition: [3, 1], ...rectangle(1, 1), mobile: false, n: 3 },
        { initialPosition: [3, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [3, 3], ...rectangle(1, 1) },
        { initialPosition: [3, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 0], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 2], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [4, 3], ...rectangle(1, 1) },
        { initialPosition: [4, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 2], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [5, 3], ...rectangle(1, 1) },
        { initialPosition: [5, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 0], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 3], ...rectangle(1, 1) },
        { initialPosition: [6, 4], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [6, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 0], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [7, 1], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [7, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 5], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [7, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [8, 0], ...sink },
        { initialPosition: [8, 1], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [8, 2], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [8, 3], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [8, 4], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [8, 5], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [8, 6], ...sink, sink: "water" },
      ],
    },
    {
      _id: new ObjectId("66bbcaed3a67736625ed2d4e"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbcaed3a67736625ed2d3f")],
      },
      cells: [
        { position: [0, 0], active: true },
        { position: [0, 1], active: true },
        { position: [0, 2], active: true },
        { position: [0, 3], active: true },
        { position: [0, 4], active: true },
        { position: [1, 0] },
        { position: [1, 1], active: true },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3], active: true },
        { position: [7, 4] },
        { position: [8, 0], active: true },
        { position: [8, 1], active: true },
        { position: [8, 2], active: true },
        { position: [8, 3], active: true },
        { position: [8, 4], active: true },
      ],
      blocks: [
        { initialPosition: [0, 0], ...rectangle(1, 1) },
        { initialPosition: [0, 1], ...rectangle(1, 1) },
        { initialPosition: [1, 1], ...source },
        { initialPosition: [1, 3], ...source, source: "water" },
        { initialPosition: [1, 4], ...rectangle(1, 1), weak: true },
        { initialPosition: [2, 1], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [2, 2], ...rectangle(1, 1) },
        { initialPosition: [2, 3], ...rectangle(1, 1), mobile: false, n: 4 },
        { initialPosition: [3, 1], ...rectangle(1, 1), mobile: false, n: 3 },
        { initialPosition: [3, 2], ...rectangle(1, 1) },
        { initialPosition: [3, 3], ...rectangle(1, 1), mobile: false, n: 3 },
        { initialPosition: [4, 1], ...rectangle(1, 1), mobile: false, n: 4 },
        { initialPosition: [4, 2], ...sink, sink: "unfilled", mobile: true },
        { initialPosition: [4, 3], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [5, 1], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [5, 2], ...rectangle(1, 1) },
        { initialPosition: [5, 3], ...rectangle(1, 1), mobile: false, n: 3 },
        { initialPosition: [6, 1], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [6, 2], ...rectangle(1, 1) },
        { initialPosition: [6, 3], ...rectangle(1, 1), mobile: false, n: 2 },
        { initialPosition: [7, 0], ...rectangle(1, 1), weak: true },
        { initialPosition: [7, 1], ...sink },
        { initialPosition: [7, 3], ...sink, sink: "water" },
        { initialPosition: [8, 3], ...rectangle(1, 1) },
        { initialPosition: [8, 4], ...rectangle(1, 1) },
      ],
    },
  ],
} satisfies AreaData);
