import { ObjectId } from "mongodb/lib/bson";

import { zArea } from "@sunblocks/game";
import type { AreaData } from "@sunblocks/game";

import { area1 } from "./area-1";
import {
  lTopRight,
  longLBottomRight,
  longTDown,
  plus,
  rectangle,
  s,
  shortLTopLeft,
  shortLTopRight,
  sink,
  source,
  tDown,
  tLeft,
  tRight,
} from "./shapes";

const level1Ids = area1.levels.map(({ _id }) => _id);

export const area2 = zArea.parse({
  _id: new ObjectId("66bbc4cd52d3058e6c9236b0"),
  models: {
    all: { model: { active: false } },
    locked: { active: "inactive", model: { active: undefined } },
    won: { active: true },
    wonBest: { active: true },
  },
  background: "inactive",
  levels: [
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236b1"),
      tutorial: true,
      condition: {
        type: "levels",
        count: 12,
        levelIds: level1Ids,
      },
      cells: [
        { position: [0, 1] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [2, 1] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source },
        { initialPosition: [1, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 2], ...rectangle(1, 1) },
        { initialPosition: [2, 1], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236b2"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c9236b1")],
      },
      cells: [
        { position: [0, 1] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [4, 1] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source },
        { initialPosition: [1, 1], ...rectangle(1, 1) },
        { initialPosition: [2, 2], ...rectangle(1, 1) },
        { initialPosition: [3, 0], ...rectangle(1, 1) },
        { initialPosition: [3, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [4, 1], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236b3"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c9236b1")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source },
        { initialPosition: [0, 2], ...rectangle(3, 1) },
        { initialPosition: [1, 0], ...rectangle(1, 1) },
        { initialPosition: [3, 0], ...rectangle(2, 2), active: false },
        { initialPosition: [5, 0], ...tRight },
        { initialPosition: [5, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 2], ...rectangle(1, 1) },
        { initialPosition: [7, 1], ...rectangle(1, 1) },
        { initialPosition: [7, 2], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236b4"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c9236b1")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [0, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [0, 3], ...rectangle(1, 1) },
        { initialPosition: [1, 0], ...rectangle(1, 1) },
        { initialPosition: [1, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 2], ...rectangle(1, 1) },
        { initialPosition: [1, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 3], ...rectangle(1, 1) },
        { initialPosition: [3, 0], ...rectangle(1, 1) },
        { initialPosition: [3, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 3], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236b5"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c9236b1")],
      },
      cells: [
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [1, 1] },
        { position: [1, 4] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [5, 1] },
        { position: [5, 4] },
        { position: [6, 1] },
        { position: [6, 2] },
      ],
      blocks: [
        { initialPosition: [0, 2], ...source },
        { initialPosition: [2, 0], ...rectangle(1, 1) },
        { initialPosition: [2, 1], ...rectangle(1, 3) },
        { initialPosition: [2, 4], ...rectangle(3, 1), active: false },
        { initialPosition: [3, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 2], ...rectangle(1, 1) },
        { initialPosition: [3, 3], ...rectangle(1, 1) },
        { initialPosition: [4, 0], ...rectangle(1, 1) },
        { initialPosition: [4, 1], ...rectangle(1, 3), active: false },
        { initialPosition: [5, 1], ...rectangle(2, 1) },
        { initialPosition: [6, 2], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236b6"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c9236b1")],
      },
      cells: [
        { position: [0, 3] },
        { position: [1, 3] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [4, 3] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
      ],
      blocks: [
        { initialPosition: [0, 3], ...source },
        { initialPosition: [2, 1], ...rectangle(1, 1) },
        { initialPosition: [2, 2], ...rectangle(2, 1) },
        { initialPosition: [2, 3], ...rectangle(2, 1), active: false },
        { initialPosition: [2, 4], ...shortLTopLeft },
        { initialPosition: [3, 5], ...rectangle(1, 1) },
        { initialPosition: [5, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 4], ...rectangle(1, 1), active: false },
        { initialPosition: [6, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [6, 3], ...sink },
        { initialPosition: [6, 4], ...rectangle(1, 1) },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236b7"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c9236b1")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 2], ...rectangle(3, 1) },
        { initialPosition: [0, 3], ...rectangle(1, 2), active: false },
        { initialPosition: [1, 0], ...rectangle(1, 2), active: false },
        { initialPosition: [1, 3], ...rectangle(1, 2) },
        { initialPosition: [2, 4], ...rectangle(2, 1), active: false },
        { initialPosition: [3, 0], ...rectangle(2, 1), active: false },
        { initialPosition: [3, 1], ...rectangle(2, 1) },
        { initialPosition: [3, 2], ...rectangle(1, 2), active: false },
        { initialPosition: [4, 2], ...rectangle(1, 2), active: false },
        { initialPosition: [4, 4], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236b8"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c9236b1")],
      },
      cells: [
        { position: [0, 2] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [8, 2] },
      ],
      blocks: [
        { initialPosition: [0, 2], ...source },
        { initialPosition: [1, 1], ...rectangle(1, 1) },
        { initialPosition: [1, 2], ...rectangle(1, 2), active: false },
        { initialPosition: [2, 0], ...rectangle(1, 2), active: false },
        { initialPosition: [2, 2], ...rectangle(1, 2) },
        { initialPosition: [3, 1], ...rectangle(1, 2) },
        { initialPosition: [4, 1], ...rectangle(2, 1) },
        { initialPosition: [4, 2], ...rectangle(1, 2) },
        { initialPosition: [6, 1], ...rectangle(1, 3), active: false },
        { initialPosition: [6, 4], ...rectangle(1, 1) },
        { initialPosition: [7, 1], ...rectangle(1, 1) },
        { initialPosition: [8, 2], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236b9"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c9236b1")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 1], ...rectangle(1, 2) },
        { initialPosition: [1, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 2], ...rectangle(2, 2), active: false },
        { initialPosition: [2, 0], ...rectangle(2, 2) },
        { initialPosition: [3, 2], ...rectangle(1, 1) },
        { initialPosition: [4, 1], ...rectangle(1, 2), active: false },
        { initialPosition: [4, 3], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236ba"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c9236b1")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 2], ...rectangle(3, 3) },
        { initialPosition: [1, 0], ...rectangle(2, 1) },
        { initialPosition: [2, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 5], ...rectangle(3, 1), active: false },
        { initialPosition: [3, 0], ...rectangle(3, 1) },
        { initialPosition: [3, 1], ...rectangle(2, 2), active: false },
        { initialPosition: [3, 3], ...rectangle(2, 2) },
        { initialPosition: [5, 1], ...rectangle(3, 3), active: false },
        { initialPosition: [5, 4], ...rectangle(1, 1) },
        { initialPosition: [5, 5], ...rectangle(2, 1), active: false },
        { initialPosition: [7, 5], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236bb"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c9236b1")],
      },
      gridWidth: 7,
      cells: [
        { position: [0, 5] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [8, 1] },
      ],
      blocks: [
        { initialPosition: [1, 1], ...source },
        { initialPosition: [1, 3], ...rectangle(1, 1) },
        { initialPosition: [2, 1], ...rectangle(5, 1) },
        { initialPosition: [2, 2], ...rectangle(1, 3), active: false },
        { initialPosition: [2, 5], ...rectangle(2, 1), active: false },
        { initialPosition: [3, 0], ...rectangle(1, 1) },
        { initialPosition: [3, 2], ...rectangle(1, 3), active: false },
        { initialPosition: [4, 2], ...rectangle(1, 3) },
        { initialPosition: [4, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 2], ...rectangle(1, 3), active: false },
        { initialPosition: [5, 5], ...rectangle(2, 1), active: false },
        { initialPosition: [6, 2], ...rectangle(1, 3), active: false },
        { initialPosition: [7, 3], ...rectangle(1, 1) },
        { initialPosition: [7, 5], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236bc"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c9236b1")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [0, 7] },
        { position: [0, 8] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [1, 7] },
        { position: [1, 8] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [2, 7] },
        { position: [2, 8] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 6] },
        { position: [3, 7] },
        { position: [3, 8] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 6] },
        { position: [4, 7] },
        { position: [4, 8] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 6] },
        { position: [5, 7] },
        { position: [5, 8] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [6, 7] },
        { position: [6, 8] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [7, 7] },
        { position: [7, 8] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [8, 7] },
        { position: [8, 8] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 7], ...rectangle(2, 2), active: false },
        { initialPosition: [1, 4], ...rectangle(1, 3), active: false },
        { initialPosition: [2, 0], ...longTDown },
        { initialPosition: [2, 3], ...rectangle(1, 5), active: false },
        { initialPosition: [3, 0], ...rectangle(5, 1) },
        { initialPosition: [3, 6], ...tDown },
        { initialPosition: [4, 6], ...tRight },
        { initialPosition: [5, 7], ...tLeft },
        { initialPosition: [6, 1], ...rectangle(3, 3), active: false },
        { initialPosition: [7, 4], ...rectangle(1, 1) },
        { initialPosition: [8, 8], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236bd"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c9236b1")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...s },
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 3], ...shortLTopRight },
        { initialPosition: [1, 1], ...plus, active: false },
        { initialPosition: [1, 3], ...rectangle(1, 1) },
        { initialPosition: [3, 0], ...rectangle(2, 2) },
        { initialPosition: [3, 3], ...rectangle(3, 1), active: false },
        { initialPosition: [4, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 1], ...plus },
        { initialPosition: [6, 0], ...rectangle(2, 1), active: false },
        { initialPosition: [7, 4], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236be"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c9236b1")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [3, 7] },
        { position: [3, 8] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [4, 7] },
        { position: [4, 8] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [5, 7] },
        { position: [5, 8] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [6, 7] },
        { position: [6, 8] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [7, 7] },
        { position: [7, 8] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [8, 7] },
        { position: [8, 8] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 3], ...rectangle(3, 3) },
        { initialPosition: [1, 0], ...rectangle(2, 2), active: false },
        { initialPosition: [3, 0], ...rectangle(1, 3) },
        { initialPosition: [3, 3], ...rectangle(3, 3), active: false },
        { initialPosition: [3, 7], ...rectangle(2, 1), active: false },
        { initialPosition: [4, 1], ...rectangle(2, 2), active: false },
        { initialPosition: [5, 5], ...longLBottomRight },
        { initialPosition: [5, 8], ...rectangle(3, 1) },
        { initialPosition: [7, 3], ...rectangle(2, 2) },
        { initialPosition: [8, 8], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236bf"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c9236b1")],
      },
      cells: [
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 10] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [4, 7] },
        { position: [4, 8] },
        { position: [4, 9] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 10] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [5, 7] },
        { position: [5, 8] },
        { position: [5, 9] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 10] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [6, 7] },
        { position: [6, 8] },
        { position: [6, 9] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [9, 4] },
        { position: [9, 5] },
        { position: [9, 6] },
        { position: [10, 4] },
        { position: [10, 5] },
        { position: [10, 6] },
      ],
      blocks: [
        { initialPosition: [0, 4], ...source },
        { initialPosition: [0, 6], ...rectangle(3, 1) },
        { initialPosition: [1, 5], ...rectangle(3, 1), active: false },
        { initialPosition: [4, 0], ...rectangle(1, 1) },
        { initialPosition: [4, 4], ...rectangle(3, 3), active: false },
        { initialPosition: [4, 7], ...rectangle(2, 2) },
        { initialPosition: [4, 9], ...lTopRight, active: false },
        { initialPosition: [5, 0], ...s, active: false },
        { initialPosition: [5, 3], ...rectangle(2, 1), active: false },
        { initialPosition: [5, 9], ...rectangle(1, 1) },
        { initialPosition: [6, 7], ...rectangle(1, 3) },
        { initialPosition: [7, 4], ...tRight, active: false },
        { initialPosition: [7, 6], ...rectangle(2, 1) },
        { initialPosition: [9, 4], ...s },
        { initialPosition: [10, 6], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236c0"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c9236b1")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [2, 0] },
        { position: [2, 2] },
        { position: [2, 4] },
        { position: [3, 0] },
        { position: [3, 2] },
        { position: [3, 4] },
        { position: [4, 0] },
        { position: [4, 2] },
        { position: [4, 4] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 1], ...rectangle(2, 1) },
        { initialPosition: [0, 3], ...rectangle(2, 1), active: false },
        { initialPosition: [0, 4], ...rectangle(2, 1), active: false },
        { initialPosition: [1, 0], ...rectangle(1, 1) },
        { initialPosition: [1, 2], ...rectangle(2, 1), active: false },
        { initialPosition: [2, 4], ...rectangle(2, 1), active: false },
        { initialPosition: [3, 0], ...rectangle(1, 1) },
        { initialPosition: [3, 2], ...rectangle(1, 1) },
        { initialPosition: [4, 0], ...rectangle(3, 1), active: false },
        { initialPosition: [4, 2], ...rectangle(2, 1), active: false },
        { initialPosition: [4, 4], ...rectangle(2, 1) },
        { initialPosition: [5, 1], ...rectangle(1, 1) },
        { initialPosition: [6, 1], ...rectangle(1, 1) },
        { initialPosition: [6, 4], ...sink },
      ],
    },
  ],
} satisfies AreaData);
