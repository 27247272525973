import { ObjectId } from "mongodb/lib/bson";

import type { AreaData, BlockData } from "@sunblocks/game";
import { zArea } from "@sunblocks/game";

import { area5 } from "./area-5";
import {
  lBottomLeft,
  lTopLeft,
  lTopRight,
  longTDown,
  plus,
  rectangle,
  reverseLBottomLeft,
  reverseLTopLeft,
  reverseLTopRight,
  s,
  shortLBottomLeft,
  shortLBottomRight,
  shortLTopLeft,
  shortLTopRight,
  sink,
  source,
  tDown,
  tLeft,
  tRight,
  tUp,
  z,
  z90,
} from "./shapes";

const level5Ids = area5.levels.map(({ _id }) => _id);

export const area6 = zArea.parse({
  _id: new ObjectId("66bbc9503a67736625ed297a"),
  models: {
    all: { active: true, model: { weak: true } },
    locked: { active: false },
  },
  background: "weak",
  levels: [
    {
      _id: new ObjectId("66bbc95e3a67736625ed29c8"),
      tutorial: true,
      condition: {
        type: "levels",
        count: 12,
        levelIds: level5Ids,
      },
      cells: [
        { position: [0, 1] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [2, 1] },
        { position: [2, 2] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source },
        { initialPosition: [1, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [2, 1], ...sink },
        { initialPosition: [2, 2], ...rectangle(1, 1) },
      ],
    },
    {
      _id: new ObjectId("66bbc9503a67736625ed297c"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc95e3a67736625ed29c8")],
      },
      cells: [
        { position: [0, 1] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [4, 1] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source },
        { initialPosition: [1, 1], ...rectangle(1, 1), weak: true },
        { initialPosition: [2, 1], ...rectangle(1, 1), weak: true },
        { initialPosition: [3, 1], ...rectangle(1, 1) },
        { initialPosition: [4, 1], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc9503a67736625ed297d"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc95e3a67736625ed29c8")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source },
        { initialPosition: [0, 2], ...rectangle(3, 1), weak: true },
        { initialPosition: [1, 0], ...rectangle(1, 1) },
        { initialPosition: [3, 0], ...rectangle(2, 2), active: false },
        { initialPosition: [5, 0], ...tRight, weak: true },
        { initialPosition: [5, 1], ...rectangle(1, 1), weak: true },
        { initialPosition: [5, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [7, 1], ...rectangle(1, 1) },
        { initialPosition: [7, 2], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc9503a67736625ed297e"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc95e3a67736625ed29c8")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [9, 0] },
        { position: [9, 1] },
        { position: [9, 2] },
        { position: [9, 3] },
        { position: [9, 4] },
        { position: [9, 5] },
        { position: [10, 0] },
        { position: [10, 1] },
        { position: [10, 2] },
        { position: [10, 3] },
        { position: [10, 4] },
        { position: [10, 5] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 1], ...rectangle(2, 2), active: false },
        { initialPosition: [1, 4], ...rectangle(1, 1), weak: true },
        { initialPosition: [3, 0], ...tDown, active: false },
        { initialPosition: [3, 3], ...rectangle(2, 2), active: false },
        { initialPosition: [6, 0], ...rectangle(3, 3), weak: true },
        { initialPosition: [7, 4], ...rectangle(1, 1), weak: true },
        { initialPosition: [9, 0], ...rectangle(2, 1) },
        { initialPosition: [9, 1], ...lTopLeft, weak: true },
        { initialPosition: [10, 2], ...rectangle(1, 1) },
        { initialPosition: [10, 3], ...rectangle(1, 1), weak: true },
        { initialPosition: [9, 4], ...rectangle(2, 1), weak: true },
        { initialPosition: [10, 5], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc9503a67736625ed297f"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc95e3a67736625ed29c8")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [0, 2], ...rectangle(1, 1) },
        { initialPosition: [0, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 3], ...rectangle(1, 1) },
        { initialPosition: [2, 0], ...rectangle(1, 1), weak: true },
        { initialPosition: [2, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 1], ...rectangle(1, 1), weak: true },
        { initialPosition: [3, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [3, 3], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc9503a67736625ed2980"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc95e3a67736625ed29c8")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 1], ...rectangle(1, 3), weak: true },
        { initialPosition: [0, 4], ...rectangle(3, 1), weak: true },
        { initialPosition: [1, 1], ...rectangle(3, 1), weak: true },
        { initialPosition: [1, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [1, 3], ...rectangle(2, 1) },
        { initialPosition: [3, 2], ...rectangle(1, 3), weak: true },
        { initialPosition: [4, 1], ...shortLTopLeft, weak: true },
        { initialPosition: [5, 2], ...shortLBottomRight, weak: true },
        { initialPosition: [6, 5], ...sink },
        ...(
          [
            { initialPosition: [2, 2], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, mobile: false, active: false })),
      ],
    },
    {
      _id: new ObjectId("66bbc9503a67736625ed2981"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc95e3a67736625ed29c8")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 1], ...rectangle(1, 1) },
        { initialPosition: [0, 3], ...rectangle(1, 2), weak: true },
        { initialPosition: [0, 6], ...rectangle(1, 1) },
        { initialPosition: [1, 2], ...tDown, weak: true },
        { initialPosition: [2, 1], ...rectangle(2, 2), weak: true },
        { initialPosition: [2, 4], ...rectangle(2, 2), weak: true },
        { initialPosition: [3, 0], ...tRight, weak: true },
        { initialPosition: [3, 2], ...plus, weak: true },
        { initialPosition: [3, 5], ...tLeft, weak: true },
        { initialPosition: [5, 1], ...rectangle(2, 2), weak: true },
        { initialPosition: [5, 4], ...rectangle(2, 2), weak: true },
        { initialPosition: [6, 2], ...tUp, weak: true },
        { initialPosition: [8, 3], ...rectangle(1, 3), weak: true },
        { initialPosition: [8, 6], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc9503a67736625ed2982"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc95e3a67736625ed29c8")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 1], ...rectangle(1, 1), weak: true },
        { initialPosition: [0, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [0, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 0], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 1], ...rectangle(1, 1), weak: true },
        { initialPosition: [2, 3], ...rectangle(1, 1) },
        { initialPosition: [4, 0], ...rectangle(1, 1), weak: true },
        { initialPosition: [4, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 2], ...rectangle(1, 1), weak: true },
        { initialPosition: [6, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 4], ...sink },
        ...(
          [
            { initialPosition: [2, 2], ...rectangle(1, 1) },
            { initialPosition: [2, 4], ...rectangle(1, 1) },
            { initialPosition: [4, 3], ...rectangle(1, 1) },
            { initialPosition: [6, 0], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, mobile: false, weak: true })),
      ],
    },
    {
      _id: new ObjectId("66bbc9503a67736625ed2983"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc95e3a67736625ed29c8")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 3], ...rectangle(1, 1), weak: true },
        { initialPosition: [2, 1], ...rectangle(1, 1) },
        { initialPosition: [4, 0], ...rectangle(1, 1) },
        { initialPosition: [4, 3], ...rectangle(1, 1) },
        { initialPosition: [6, 2], ...rectangle(1, 1) },
        { initialPosition: [8, 0], ...rectangle(1, 1) },
        { initialPosition: [8, 3], ...rectangle(1, 1), weak: true },
        { initialPosition: [8, 6], ...sink },
        ...(
          [
            { initialPosition: [1, 4], ...rectangle(1, 1) },
            { initialPosition: [3, 6], ...rectangle(1, 1) },
            { initialPosition: [5, 0], ...rectangle(1, 1) },
            { initialPosition: [5, 3], ...rectangle(1, 1) },
            { initialPosition: [5, 6], ...rectangle(1, 1) },
            { initialPosition: [7, 5], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, mobile: false, active: false })),
        ...(
          [
            { initialPosition: [1, 1], ...rectangle(1, 1) },
            { initialPosition: [1, 2], ...rectangle(1, 1) },
            { initialPosition: [1, 5], ...rectangle(1, 1) },
            { initialPosition: [3, 0], ...rectangle(1, 1) },
            { initialPosition: [3, 2], ...rectangle(1, 1) },
            { initialPosition: [3, 3], ...rectangle(1, 1) },
            { initialPosition: [3, 5], ...rectangle(1, 1) },
            { initialPosition: [5, 1], ...rectangle(1, 1) },
            { initialPosition: [5, 4], ...rectangle(1, 1) },
            { initialPosition: [7, 1], ...rectangle(1, 1) },
            { initialPosition: [7, 2], ...rectangle(1, 1) },
            { initialPosition: [7, 4], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, mobile: false, weak: true })),
      ],
    },
    {
      _id: new ObjectId("66bbc9503a67736625ed2984"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc95e3a67736625ed29c8")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 6], ...sink },
        { initialPosition: [1, 0], ...rectangle(1, 1) },
        { initialPosition: [1, 5], ...rectangle(1, 1) },
        { initialPosition: [2, 3], ...rectangle(1, 1) },
        { initialPosition: [3, 2], ...rectangle(1, 1) },
        { initialPosition: [5, 2], ...rectangle(1, 1) },
        { initialPosition: [5, 5], ...rectangle(1, 1) },
        { initialPosition: [6, 0], ...rectangle(1, 1) },
        { initialPosition: [6, 3], ...rectangle(1, 1), weak: true },
        { initialPosition: [8, 0], ...sink },
        { initialPosition: [8, 6], ...sink },
        ...(
          [
            { initialPosition: [0, 1], ...rectangle(1, 1) },
            { initialPosition: [0, 3], ...rectangle(1, 1) },
            { initialPosition: [1, 1], ...rectangle(1, 1) },
            { initialPosition: [1, 3], ...rectangle(1, 1) },
            { initialPosition: [1, 4], ...rectangle(1, 1) },
            { initialPosition: [1, 6], ...rectangle(1, 1) },
            { initialPosition: [2, 1], ...rectangle(1, 1) },
            { initialPosition: [2, 4], ...rectangle(1, 1) },
            { initialPosition: [3, 6], ...rectangle(1, 1) },
            { initialPosition: [4, 0], ...rectangle(1, 1) },
            { initialPosition: [4, 1], ...rectangle(1, 1) },
            { initialPosition: [4, 3], ...rectangle(1, 1) },
            { initialPosition: [4, 4], ...rectangle(1, 1) },
            { initialPosition: [4, 5], ...rectangle(1, 1) },
            { initialPosition: [4, 6], ...rectangle(1, 1) },
            { initialPosition: [5, 1], ...rectangle(1, 1) },
            { initialPosition: [5, 4], ...rectangle(1, 1) },
            { initialPosition: [5, 6], ...rectangle(1, 1) },
            { initialPosition: [6, 1], ...rectangle(1, 1) },
            { initialPosition: [6, 2], ...rectangle(1, 1) },
            { initialPosition: [6, 6], ...rectangle(1, 1) },
            { initialPosition: [7, 4], ...rectangle(1, 1) },
            { initialPosition: [8, 1], ...rectangle(1, 1) },
            { initialPosition: [8, 2], ...rectangle(1, 1) },
            { initialPosition: [8, 4], ...rectangle(1, 1) },
            { initialPosition: [8, 5], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, mobile: false, weak: true })),
      ],
    },
    {
      _id: new ObjectId("66bbc9503a67736625ed2985"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc95e3a67736625ed29c8")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5], active: true },
        { position: [0, 6], active: true },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 0], active: true },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0], active: true },
        { position: [3, 1], active: true },
        { position: [3, 2], active: true },
        { position: [3, 3], active: true },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5], active: true },
        { position: [8, 6] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 0], ...z90, weak: true },
        { initialPosition: [0, 4], ...reverseLTopRight, weak: true },
        { initialPosition: [5, 2], ...rectangle(1, 3), weak: true },
        { initialPosition: [6, 0], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 5], ...shortLTopLeft, weak: true },
        { initialPosition: [8, 6], ...sink },
        ...(
          [
            { initialPosition: [3, 0], ...rectangle(1, 1) },
            { initialPosition: [3, 1], ...rectangle(1, 1) },
            { initialPosition: [3, 2], ...rectangle(1, 1) },
            { initialPosition: [3, 6], ...rectangle(1, 1) },
            { initialPosition: [6, 4], ...rectangle(1, 1) },
            { initialPosition: [6, 5], ...rectangle(1, 1) },
            { initialPosition: [6, 6], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, mobile: false, active: false })),
      ],
    },
    {
      _id: new ObjectId("66bbc9503a67736625ed2986"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc95e3a67736625ed29c8")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1], active: true },
        { position: [0, 2], active: true },
        { position: [0, 3], active: true },
        { position: [0, 4] },
        { position: [0, 5], active: true },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [2, 0], active: true },
        { position: [2, 1], active: true },
        { position: [2, 2], active: true },
        { position: [2, 3] },
        { position: [2, 4], active: true },
        { position: [2, 5], active: true },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [4, 0], active: true },
        { position: [4, 1], active: true },
        { position: [4, 2] },
        { position: [4, 3], active: true },
        { position: [4, 4], active: true },
        { position: [4, 5], active: true },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [7, 0], active: true },
        { position: [7, 1] },
        { position: [7, 2], active: true },
        { position: [7, 3], active: true },
        { position: [7, 4], active: true },
        { position: [7, 5], active: true },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [9, 0] },
        { position: [9, 1] },
        { position: [9, 2] },
        { position: [9, 3] },
        { position: [9, 4] },
        { position: [9, 5] },
        { position: [10, 0] },
        { position: [10, 1] },
        { position: [10, 2] },
        { position: [10, 3] },
        { position: [10, 4] },
        { position: [10, 5] },
        { position: [11, 0] },
        { position: [11, 1] },
        { position: [11, 2] },
        { position: [11, 3] },
        { position: [11, 4] },
        { position: [11, 5] },
        { position: [12, 0] },
        { position: [12, 1], active: true },
        { position: [12, 2], active: true },
        { position: [12, 3], active: true },
        { position: [12, 4], active: true },
        { position: [12, 5] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 1], ...rectangle(5, 1), weak: true },
        { initialPosition: [0, 3], ...rectangle(2, 2), active: false },
        { initialPosition: [0, 5], ...rectangle(5, 1), active: false },
        { initialPosition: [2, 4], ...rectangle(3, 1), active: false },
        { initialPosition: [5, 0], ...lTopRight, active: false },
        { initialPosition: [5, 2], ...lBottomLeft, active: false },
        { initialPosition: [8, 0], ...lTopLeft, weak: true },
        { initialPosition: [8, 3], ...rectangle(3, 3), active: false },
        { initialPosition: [9, 1], ...rectangle(3, 1), weak: true },
        { initialPosition: [11, 0], ...reverseLBottomLeft, weak: true },
        { initialPosition: [12, 5], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc9503a67736625ed2987"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc95e3a67736625ed29c8")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [3, 0], active: true },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [4, 0], active: true },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [5, 0], active: true },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [6, 0], active: true },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [7, 0], active: true },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [9, 0] },
        { position: [9, 1] },
        { position: [9, 2] },
        { position: [9, 3] },
        { position: [9, 4] },
        { position: [9, 5] },
        { position: [10, 0] },
        { position: [10, 1] },
        { position: [10, 2], active: true },
        { position: [10, 3] },
        { position: [10, 4] },
        { position: [10, 5] },
        { position: [11, 0] },
        { position: [11, 1] },
        { position: [11, 2], active: true },
        { position: [11, 3], active: true },
        { position: [11, 4] },
        { position: [11, 5] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 2], ...lTopRight, active: false },
        { initialPosition: [1, 2], ...lBottomLeft, active: false },
        { initialPosition: [4, 0], ...shortLBottomLeft, active: false },
        { initialPosition: [4, 1], ...z, weak: true },
        { initialPosition: [4, 3], ...reverseLTopRight, active: false },
        { initialPosition: [6, 0], ...rectangle(3, 3), weak: true },
        { initialPosition: [6, 3], ...rectangle(3, 3), active: false },
        { initialPosition: [9, 0], ...lTopLeft, weak: true },
        { initialPosition: [9, 2], ...s, active: false },
        { initialPosition: [9, 4], ...shortLBottomRight, weak: true },
        { initialPosition: [11, 5], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc9503a67736625ed2988"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc95e3a67736625ed29c8")],
      },
      cells: [
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [5, 1] },
        { position: [5, 2] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source },
        { initialPosition: [1, 1], ...rectangle(1, 1), n: 3 },
        { initialPosition: [1, 2], ...rectangle(1, 1), n: 3 },
        { initialPosition: [2, 0], ...rectangle(1, 1), weak: true },
        { initialPosition: [2, 1], ...rectangle(2, 2), active: false },
        { initialPosition: [2, 3], ...rectangle(2, 1), weak: true },
        { initialPosition: [4, 2], ...rectangle(1, 1), n: 4 },
        { initialPosition: [5, 2], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc9503a67736625ed2989"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc95e3a67736625ed29c8")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [9, 0] },
        { position: [9, 1] },
        { position: [9, 2] },
        { position: [9, 3] },
        { position: [9, 4] },
        { position: [9, 5] },
        { position: [9, 6] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 3], ...rectangle(1, 1), mobile: false, n: 3 },
        { initialPosition: [0, 5], ...rectangle(1, 2), active: false },
        { initialPosition: [1, 1], ...rectangle(2, 1), weak: true },
        { initialPosition: [2, 4], ...rectangle(1, 2), active: false },
        { initialPosition: [3, 1], ...shortLTopRight, weak: true },
        { initialPosition: [3, 3], ...rectangle(1, 2), weak: true },
        { initialPosition: [4, 3], ...rectangle(1, 1), mobile: false, n: 4 },
        { initialPosition: [4, 5], ...lTopRight, active: false },
        { initialPosition: [5, 4], ...rectangle(2, 1), active: false },
        { initialPosition: [6, 0], ...rectangle(1, 3), active: false },
        { initialPosition: [7, 2], ...rectangle(3, 1), weak: true },
        { initialPosition: [7, 3], ...rectangle(1, 1), mobile: false, n: 4 },
        { initialPosition: [7, 4], ...rectangle(2, 1), active: false },
        { initialPosition: [8, 5], ...rectangle(1, 2), weak: true },
        { initialPosition: [9, 3], ...rectangle(1, 1), mobile: false, n: 3 },
        { initialPosition: [9, 6], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc9503a67736625ed298a"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc95e3a67736625ed29c8")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [0, 7] },
        { position: [0, 8] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [1, 8] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [2, 7] },
        { position: [2, 8] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [3, 7] },
        { position: [3, 8] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [4, 7] },
        { position: [4, 8] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [5, 7] },
        { position: [5, 8] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [6, 7] },
        { position: [6, 8] },
        { position: [7, 0] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [7, 7] },
        { position: [7, 8] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [8, 7] },
        { position: [8, 8] },
      ],
      blocks: [
        { initialPosition: [0, 3], ...longTDown, active: false },
        { initialPosition: [1, 1], ...source },
        { initialPosition: [2, 2], ...shortLTopLeft, weak: true },
        { initialPosition: [2, 5], ...shortLTopRight, weak: true },
        { initialPosition: [3, 0], ...reverseLTopLeft, active: false },
        { initialPosition: [3, 3], ...rectangle(3, 3), n: 8 },
        { initialPosition: [3, 6], ...plus, active: false },
        { initialPosition: [4, 1], ...shortLTopLeft, active: false },
        { initialPosition: [5, 2], ...shortLBottomLeft, weak: true },
        { initialPosition: [5, 5], ...shortLBottomRight, weak: true },
        { initialPosition: [6, 0], ...rectangle(3, 1), active: false },
        { initialPosition: [6, 3], ...tUp, active: false },
        { initialPosition: [7, 7], ...sink },
        { initialPosition: [8, 1], ...rectangle(1, 3), active: false },
        { initialPosition: [8, 5], ...rectangle(1, 3), active: false },
      ],
    },
  ],
} satisfies AreaData);
