import { ObjectId } from "mongodb/lib/bson";

import { zArea } from "@sunblocks/game";
import type { AreaData } from "@sunblocks/game";

import {
  longLBottomLeft,
  longLBottomRight,
  longLTopLeft,
  longLTopRight,
  longTLeft,
  rectangle,
  shortLBottomLeft,
  shortLTopRight,
  sink,
  source,
  tLeft,
  tRight,
  tUp,
} from "./shapes";

export const area1 = zArea.parse({
  _id: new ObjectId("66bbc3c24dbfe28cfe98b4c9"),
  levels: [
    {
      _id: new ObjectId("66bbc48a52d3058e6c9234b9"),
      tutorial: true,
      cells: [
        { position: [0, 1] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [2, 1] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source },
        { initialPosition: [1, 2], ...rectangle(1, 1) },
        { initialPosition: [2, 1], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c92369d"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc48a52d3058e6c9234b9")],
      },
      cells: [
        { position: [0, 1] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [4, 1] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source },
        { initialPosition: [1, 0], ...rectangle(1, 1) },
        { initialPosition: [1, 1], ...rectangle(1, 1) },
        { initialPosition: [3, 0], ...rectangle(1, 1) },
        { initialPosition: [4, 1], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c92369e"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc48a52d3058e6c9234b9")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source },
        { initialPosition: [0, 2], ...rectangle(3, 1) },
        { initialPosition: [1, 0], ...rectangle(1, 1) },
        { initialPosition: [3, 0], ...tRight },
        { initialPosition: [5, 2], ...rectangle(1, 1) },
        { initialPosition: [7, 1], ...rectangle(1, 1) },
        { initialPosition: [7, 2], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c92369f"),
      tutorial: true,
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc48a52d3058e6c9234b9")],
      },
      cells: [
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [2, 2] },
        { position: [3, 2] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source },
        { initialPosition: [0, 2], ...rectangle(1, 1) },
        { initialPosition: [1, 2], ...rectangle(1, 2) },
        { initialPosition: [3, 2], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236a0"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c92369f")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 1], ...rectangle(1, 1) },
        { initialPosition: [1, 0], ...longTLeft },
        { initialPosition: [3, 0], ...rectangle(2, 2) },
        { initialPosition: [5, 1], ...longLTopLeft },
        { initialPosition: [7, 3], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236a1"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c92369f")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...longLBottomRight },
        { initialPosition: [0, 0], ...source },
        { initialPosition: [3, 1], ...rectangle(2, 1) },
        { initialPosition: [3, 2], ...rectangle(2, 1) },
        { initialPosition: [5, 1], ...longLTopLeft },
        { initialPosition: [7, 3], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236a5"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c92369f")],
      },
      cells: [
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [1, 1] },
        { position: [1, 3] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [7, 0] },
        { position: [7, 4] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source },
        { initialPosition: [0, 3], ...rectangle(1, 1) },
        { initialPosition: [1, 1], ...tRight },
        { initialPosition: [2, 2], ...tLeft },
        { initialPosition: [5, 2], ...tUp },
        { initialPosition: [8, 4], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236a6"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c92369f")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [4, 0] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [6, 0] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 1], ...shortLTopRight },
        { initialPosition: [0, 4], ...rectangle(2, 1) },
        { initialPosition: [2, 2], ...shortLBottomLeft },
        { initialPosition: [4, 3], ...rectangle(2, 2) },
        { initialPosition: [5, 0], ...rectangle(1, 3) },
        { initialPosition: [7, 1], ...rectangle(1, 3) },
        { initialPosition: [7, 4], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236a2"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c92369f")],
      },
      cells: [
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [3, 7] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [7, 3] },
      ],
      blocks: [
        { initialPosition: [1, 1], ...longLBottomRight },
        { initialPosition: [1, 1], ...source },
        { initialPosition: [1, 4], ...longLBottomLeft },
        { initialPosition: [2, 1], ...rectangle(1, 1) },
        { initialPosition: [4, 1], ...longLTopRight },
        { initialPosition: [4, 4], ...longLTopLeft },
        { initialPosition: [6, 6], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236a3"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c92369f")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 3] },
        { position: [4, 4] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 3], ...rectangle(2, 1) },
        { initialPosition: [2, 1], ...rectangle(1, 3) },
        { initialPosition: [3, 1], ...rectangle(1, 2) },
        { initialPosition: [4, 0], ...rectangle(1, 1) },
        { initialPosition: [4, 4], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236a4"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c92369f")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 3] },
        { position: [6, 4] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 3], ...rectangle(2, 1) },
        { initialPosition: [2, 0], ...rectangle(1, 3) },
        { initialPosition: [4, 0], ...rectangle(1, 1) },
        { initialPosition: [4, 1], ...rectangle(3, 1) },
        { initialPosition: [4, 2], ...rectangle(1, 3) },
        { initialPosition: [6, 0], ...rectangle(1, 1) },
        { initialPosition: [6, 4], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236a7"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c92369f")],
      },
      gridHeight: 9,
      cells: [
        { position: [0, 4] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 1] },
        { position: [2, 4] },
        { position: [2, 7] },
        { position: [3, 1] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 7] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [4, 7] },
        { position: [4, 8] },
        { position: [5, 1] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 7] },
        { position: [6, 1] },
        { position: [6, 4] },
        { position: [6, 7] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [7, 7] },
      ],
      blocks: [
        { initialPosition: [1, 1], ...source },
        { initialPosition: [1, 4], ...rectangle(3, 1) },
        { initialPosition: [3, 5], ...rectangle(1, 1) },
        { initialPosition: [4, 1], ...rectangle(1, 3) },
        { initialPosition: [4, 5], ...rectangle(1, 3) },
        { initialPosition: [5, 3], ...rectangle(1, 1) },
        { initialPosition: [5, 4], ...rectangle(3, 1) },
        { initialPosition: [6, 1], ...rectangle(1, 1) },
        { initialPosition: [7, 2], ...rectangle(1, 1) },
        { initialPosition: [7, 7], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236a8"),
      tutorial: true,
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c92369f")],
      },
      cells: [
        { position: [0, 0] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [1, 1], ...rectangle(1, 1) },
        { initialPosition: [1, 2], ...rectangle(1, 1) },
        { initialPosition: [2, 0], ...sink },
        { initialPosition: [2, 2], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236a9"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c9236a8")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
      ],
      blocks: [
        { initialPosition: [0, 2], ...source },
        { initialPosition: [1, 1], ...rectangle(1, 3) },
        { initialPosition: [2, 1], ...rectangle(2, 1) },
        { initialPosition: [2, 3], ...rectangle(2, 1) },
        { initialPosition: [5, 0], ...sink },
        { initialPosition: [5, 4], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236aa"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c9236a8")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...sink },
        { initialPosition: [0, 4], ...sink },
        { initialPosition: [1, 1], ...rectangle(1, 1) },
        { initialPosition: [1, 2], ...rectangle(1, 1) },
        { initialPosition: [1, 3], ...rectangle(1, 1) },
        { initialPosition: [2, 1], ...rectangle(1, 1) },
        { initialPosition: [2, 2], ...source },
        { initialPosition: [2, 3], ...rectangle(1, 1) },
        { initialPosition: [3, 1], ...rectangle(1, 1) },
        { initialPosition: [3, 2], ...rectangle(1, 1) },
        { initialPosition: [3, 3], ...rectangle(1, 1) },
        { initialPosition: [4, 0], ...sink },
        { initialPosition: [4, 4], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc4cd52d3058e6c9236ab"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc4cd52d3058e6c9236a8")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...sink },
        { initialPosition: [0, 4], ...sink },
        { initialPosition: [1, 1], ...source },
        { initialPosition: [1, 2], ...rectangle(1, 1) },
        { initialPosition: [1, 3], ...rectangle(1, 1) },
        { initialPosition: [2, 1], ...rectangle(1, 1) },
        { initialPosition: [2, 2], ...rectangle(1, 1) },
        { initialPosition: [2, 3], ...rectangle(1, 1) },
        { initialPosition: [3, 1], ...rectangle(1, 1) },
        { initialPosition: [3, 2], ...rectangle(1, 1) },
        { initialPosition: [3, 3], ...rectangle(1, 1) },
        { initialPosition: [4, 0], ...sink },
        { initialPosition: [4, 4], ...sink },
      ],
    },
  ],
} satisfies AreaData);
